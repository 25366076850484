import React from "react";
import PaddingManager from "../../components/PaddingManager";
import Header from "../../components/Header";
import { Wrap } from "./styles";
import Card from "../../components/Card";
import { useNavigate, useParams } from "react-router";
import ConfirmPasswordForm from "../../components/ConfirmPasswordForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import Button from "../../components/Button";
import { ButtonEnum } from "../../beans";
import { showAccessForm } from "../../store/UI";
import { useEffect } from "react";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";

const PasswordConfirm = () => {
	const { uid, token } = useParams();
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const passwordRequestState = useSelector(
		(state: RootState) => state.password
	);
	const isLogged = useSelector((state: RootState) => state.login.logged);

	// HOOKS
	useEffect(() => {
		isLogged && navigate(routes.home);
	}, [isLogged, navigate]);

	return uid && token ? (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<Card title={t("passwordConfirm.title")}>
						{!passwordRequestState.pwd_changed ? (
							<>
								<div
									className="body-sm"
									dangerouslySetInnerHTML={{
										__html: t("passwordConfirm.description"),
									}}
								></div>
								<div>
									<ConfirmPasswordForm uid={uid} token={token} />
								</div>
							</>
						) : (
							<>
								<p className="body-sm">{passwordRequestState.data}</p>
								<Button
									style={ButtonEnum.primary}
									onClick={() =>
										dispatch(showAccessForm({ login: true, register: false }))
									}
									loading={passwordRequestState.loading}
								>
									{t("passwordConfirm.button")}
								</Button>
							</>
						)}
					</Card>
				</PaddingManager>
			</Wrap>
		</>
	) : null;
};

export default PasswordConfirm;
