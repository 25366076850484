import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div<{ $isOver?: boolean }>`
  .drop-area {
    cursor: pointer;
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 16px;
    text-align: center;
    background: ${(props) =>
      props.$isOver ? "var(--purple10)" : "var(--gray20)"};
    border: ${(props) =>
      props.$isOver
        ? "1px solid var(--purple100)"
        : "1px dashed var(--gray30)"};
  }

  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .drop-area {
      width: 100%;
    }
  }
`;
