import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ButtonEnum, OrderSummary, ProductionTimeEnum } from "../../beans";
import {
	goToOrderShipping,
	isCheckoutRoute,
	isOrderCompletedRoute,
	isProductionRoute,
} from "../../constants/routes";
import { AppDispatch, RootState } from "../../store";

import { priceFormat } from "../../utils/format";
import Button from "../Button";
import Card from "../Card";
import Divider from "../Divider";
import LoadingOptions from "../LoadingOptions";
import { Wrap } from "./styles";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { applyCouponRequest, removeCouponRequest } from "../../store/Order";

import LoadingText from "../LoadingText";

type Props = {
	summary: OrderSummary | null;
	orderId: string | undefined;
};

const ProductionSummary = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const { orderId, summary } = props;
	const [coupon, setCoupon] = useState("");
	const [showCoupon, setShowCoupon] = useState(false);

	const production = useSelector((state: RootState) => state.production);
	const orderState = useSelector((state: RootState) => state.order);

	const goToNextStep = async () => {
		orderId && navigate(goToOrderShipping(orderId));
	};

	const handleChangeCoupon = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setCoupon(e.target.value);
	};

	const applyCoupon = () => {
		if (coupon && orderId) {
			dispatch(applyCouponRequest({ orderId: orderId, code: coupon }));
		}
	};
	const removeCoupon = () => {
		if (orderId) {
			setShowCoupon(false);
			dispatch(removeCouponRequest(orderId));
		}
	};

	return (
		<Wrap className="summary-container">
			<div className="sticky-summary">
				<Card title={t("productionSummary.title")}>
					{summary && summary.costs ? (
						<>
							<div className="title-sm">{t("productionSummary.items")}</div>
							{summary.costs?.designs.map((design, index) => (
								<div className="summary-detail items" key={index}>
									<div className="row">
										<div className="body-sm">
											<strong>{design.name}</strong>{" "}
											<small>{design.item.name}</small>
										</div>
										<div className="price title-sm">
											{orderState.loading || production.loading ? (
												<LoadingText />
											) : (
												<>{priceFormat(design.item_total)} €</>
											)}
										</div>
									</div>
								</div>
							))}
							<Divider />

							<div className="summary-detail">
								<div className="row">
									<div
										className={
											isProductionRoute(window.location.pathname)
												? `heading-sm`
												: `body-sm`
										}
									>
										{t("productionSummary.subtotal")}
									</div>
									<div
										className={
											isProductionRoute(window.location.pathname)
												? `price heading-sm`
												: `price title-sm`
										}
									>
										{orderState.loading || production.loading ? (
											<LoadingText />
										) : (
											<>{priceFormat(summary.costs.subtotal)} €</>
										)}
									</div>
								</div>

								{isProductionRoute(window.location.pathname) && (
									<>
										<div className="vat-info label-sm">
											{t("productionSummary.priceVatExcluded")}
										</div>
										<Divider />
									</>
								)}
								{!isProductionRoute(window.location.pathname) && (
									<>
										<div className="summary-detail">
											<div className="row">
												<div className="body-sm">
													{t("productionSummary.vat")}
												</div>
												<div className="price title-sm">
													{orderState.loading || production.loading ? (
														<LoadingText />
													) : (
														<>{priceFormat(summary.costs.vat)} €</>
													)}
												</div>
											</div>
										</div>
									</>
								)}
								{!isProductionRoute(window.location.pathname) &&
									!isOrderCompletedRoute(window.location.pathname) && (
										<div className="coupon-details">
											{summary.costs.coupon?.amount_discount ? (
												<>
													<div className="row">
														<div className="body-sm ">
															<div className="input-row">
																<div className="body-sm">
																	{t("productionSummary.couponLabel")}
																</div>
															</div>
														</div>
														<Button
															style={ButtonEnum.link}
															onClick={() => removeCoupon()}
														>
															{t("button.remove")}
														</Button>
													</div>
													<div className="row">
														<div className="body-sm couponCode code">
															{summary.costs.coupon.code}
														</div>
														<div className="price title-sm couponCode">
															-{" "}
															{priceFormat(
																summary.costs.coupon.amount_discount
															)}{" "}
															€
														</div>
													</div>
												</>
											) : !showCoupon ? (
												<div className="row">
													<div className="body-sm">
														<Button
															style={ButtonEnum.link}
															onClick={() => setShowCoupon(true)}
														>
															{t("productionSummary.couponButton")}
														</Button>
													</div>
												</div>
											) : (
												<div className="row">
													<div className="body-sm edit-block">
														<form onSubmit={applyCoupon}>
															<div className="input-row">
																<label className="label-md">
																	{" "}
																	{t("productionSummary.couponLabel")}
																</label>
																<input
																	id="coupon"
																	name="coupon"
																	type="text"
																	value={coupon}
																	onChange={handleChangeCoupon}
																/>
															</div>
														</form>
														<Button
															style={ButtonEnum.primary}
															onClick={() => applyCoupon()}
														>
															{t("button.apply")}
														</Button>
														<Button
															style={ButtonEnum.secondary}
															onClick={() => setShowCoupon(false)}
														>
															{t("button.cancel")}
														</Button>
													</div>
												</div>
											)}
										</div>
									)}
								{!isProductionRoute(window.location.pathname) && <Divider />}
							</div>

							{summary.shipping && (
								<>
									<div className="summary-detail">
										<div className="row">
											<div className="body-sm">
												{" "}
												{t("productionSummary.shippingCost")}
											</div>
											<div className="price title-sm">
												{summary.costs.shipping &&
													`${priceFormat(summary.costs.shipping)} €`}
											</div>
										</div>
									</div>
									<Divider />
								</>
							)}
							{!isProductionRoute(window.location.pathname) && (
								<div className="summary-detail">
									<div className="row">
										<div className="heading-md total-price">
											{" "}
											{t("productionSummary.total")}
										</div>
										<div className="price heading-md">
											{priceFormat(summary.costs.total)}{" "}
										</div>
									</div>
									{!isCheckoutRoute(window.location.pathname) &&
										!isOrderCompletedRoute(window.location.pathname) && (
											<div className="vat-info label-sm">
												{t("productionSummary.priceShippingExcluded")}
											</div>
										)}
									<Divider />
								</div>
							)}
							<div className="summary-detail">
								<div className="row">
									<div className="body-sm">
										{" "}
										{t("productionSummary.productionTime")}
									</div>
									<div className="price title-lg">
										{production.data?.sample
											? ProductionTimeEnum.sample
											: ProductionTimeEnum.production}{" "}
										{t("vocabulary.weeks")} *
									</div>
								</div>
							</div>
						</>
					) : (
						<LoadingOptions />
					)}
					<Divider />
					<div className="production-disclaimer label-sm">
						* {t("productionSummary.additionalProductionTime")}
					</div>
				</Card>
				{summary && (
					<>
						{isProductionRoute(window.location.pathname) && (
							<div className="button-container">
								<Button
									style={ButtonEnum.primary}
									onClick={() => goToNextStep()}
									disabled={!!production?.error}
									loading={production.loading || orderState.loading}
								>
									{t("productionSummary.continueToCheckout")}
								</Button>
							</div>
						)}
					</>
				)}
			</div>
		</Wrap>
	);
};

export default ProductionSummary;
