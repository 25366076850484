import { ArtworkDetail, PrintLocation } from "../beans";

export function convertCm2Px(
	cage_width_cm: number,
	cage_width_px: number
): number {
	const unit = cage_width_cm / Number(cage_width_px);

	return unit;
}

export const calclulatePreviewImageTranslation = (
	currentArtwork: ArtworkDetail,
	printLocation: PrintLocation,
	cageWidth: number
) => {
	const { position_x, position_y } = currentArtwork;

	const { real_width_cm } = printLocation;

	// ARTWORK SIZES

	const x = position_x
		? translateToPx(
				Number(cageWidth),
				Number(real_width_cm),
				Number(position_x)
			)
		: 0;
	const y = position_y
		? translateToPx(
				Number(cageWidth),
				Number(real_width_cm),
				Number(position_y)
			)
		: 0;

	return `translate(${x}px, ${y}px) scale(1)`;
};

export const getDOMMatrixScale = (matrix: DOMMatrix) => {
	const { scaleX } = decomposeMatrix(matrix);
	const scale = scaleX.toFixed(2);
	return (Number(scale) * 100).toFixed(2);
};

export const decomposeMatrix = (m: DOMMatrix) => {
	const E = (m.a + m.d) / 2;
	const F = (m.a - m.d) / 2;
	const G = (m.c + m.b) / 2;
	const H = (m.c - m.b) / 2;

	const Q = Math.sqrt(E * E + H * H);
	const R = Math.sqrt(F * F + G * G);
	const a1 = Math.atan2(G, F);
	const a2 = Math.atan2(H, E);
	const theta = (a2 - a1) / 2;
	const phi = (a2 + a1) / 2;

	// The requested parameters are then theta,
	// sx, sy, phi,
	return {
		translateX: m.e,
		translateY: m.f,
		rotate: (-phi * 180) / Math.PI,
		scaleX: Q + R,
		scaleY: Q - R,
		skew: (-theta * 180) / Math.PI,
	};
};

export const convertToCm = (
	cage_px: number | undefined,
	cage_cm: number,
	artwork_px: number
) => {
	if (!cage_px || !cage_cm || !artwork_px) return 0;
	return (Number(cage_cm) / Number(cage_px)) * Number(artwork_px);
};

export const convertToPx = (
	cage_px: number | undefined,
	cage_cm: number,
	artwork_cm: number
) => {
	if (!cage_px || !cage_cm || !artwork_cm) return 0;

	const conversion = (Number(cage_px) / Number(cage_cm)) * Number(artwork_cm);

	return conversion;
};

export const translateToPx = (
	cage_px: number,
	cage_cm: number,
	artwork_cm: number
) => {
	if (!cage_px || !cage_cm || !artwork_cm) return 0;

	const conversion = (cage_px / cage_cm) * artwork_cm;

	return conversion;
};

export const translateToCm = (
	cage_px: number,
	cage_cm: number,
	artwork_cm: number
) => {
	if (!cage_px || !cage_cm || !artwork_cm) return 0;

	const conversion = (cage_cm / cage_px) * artwork_cm;

	return conversion;
};
