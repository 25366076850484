import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import SubsectionTitle from "../../components/SubsectionTitle";
import PaddingManager from "../../components/PaddingManager";
import Header from "../../components/Header";
import { Wrap } from "./styles";
import { listOrderRequest } from "../../store/Order";
import OrderDetailsCard from "../../components/OrderDetailsCard";
import { OrderList, OrderStatusEnum } from "../../beans";
import Icon from "../../components/Icon";
import { emptyOrderPage } from "../../constants/icons";
import LoadingCanva from "../../components/LoadingCanva";
import { useTranslation } from "react-i18next";

const Production = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();

	// LOCAL STATE
	const orderList = useSelector((state: RootState) => state.order.orderList);

	// HOOKS
	useEffect(() => {
		dispatch(listOrderRequest());
	}, [dispatch]);

	const hasOrdersToShow = () => {
		if (orderList) {
			return orderList.find(
				(order: OrderList) => order.status === OrderStatusEnum.payment_made
			);
		}
	};

	return (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<SubsectionTitle title={t("production.title")}></SubsectionTitle>

					<div className="orders-list">
						{orderList ? (
							orderList.map((order: OrderList) => {
								return (
									order.status === OrderStatusEnum.payment_made && (
										<OrderDetailsCard
											order={order}
											key={`order_detail_${order.id}`}
										/>
									)
								);
							})
						) : (
							<LoadingCanva />
						)}

						{!hasOrdersToShow() && (
							<div className="empty-page">
								<Icon icon={emptyOrderPage} />
								<div className="heading-md">{t("production.noDesign")}</div>
								<div className="title-lg">{t("production.orders")}</div>
							</div>
						)}
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default Production;
