import { apiUrl } from "../constants/endpoints";
import { getAuthHeader } from "./auth";

export const getStripeSecretKey = async (
	orderId: number,
	total_amount: number
) => {
	const headers = getAuthHeader();
	const response = await fetch(`${apiUrl}/orders/orders/${orderId}/payment/`, {
		method: "POST",
		body: JSON.stringify({
			order: orderId,
			total_amount: total_amount,
		}),
		headers: headers,
		credentials: "include",
	});

	return await response.json();
};
