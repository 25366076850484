import React, { useState } from "react";
import { MouseEventHandler } from "react";
import { ItemRender } from "../../beans";
import { Wrap } from "./styles";

type Props = {
	canva: ItemRender;
	selected?: boolean;
	onClick?: MouseEventHandler<HTMLButtonElement>;
};
const RenderThumbnail = (props: Props) => {
	const { selected, canva } = props;
	const { image } = canva;
	const [loaded, setLoaded] = useState(false);

	return (
		<Wrap
			$selected={selected}
			onClick={props.onClick}
			className={loaded ? "loaded" : ""}
		>
			<img
				className={"thumb"}
				src={image}
				alt=""
				onLoad={() => {
					setLoaded(true);
				}}
			/>
		</Wrap>
	);
};

export default RenderThumbnail;
