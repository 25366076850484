import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { UserDesign } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getErrors } from "../../utils/errors";
import { showErrorModal } from "../UI";
import { DesignActionEnum } from "./actions";

export interface DesignState {
	data: [] | UserDesign[]; // TODO type better
	error: null | string | Error;
	loading: boolean;
	loaded: boolean;
	selected: UserDesign[];
}

const initialState: DesignState = {
	data: [],
	error: null,
	loading: false,
	loaded: false,
	selected: [],
};

// SAGAS
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getDesignSaga(): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();

		const response = yield call(fetch, `${apiUrl}/designs/userdesign/`, {
			headers: headers,
			credentials: "include",
		});

		const data = yield response.json();

		if (response.ok) {
			yield put(getDesignSuccess(data));
		} else {
			yield put(getDesignFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		// console.error("ERROR", error);
		yield put(showErrorModal(String(error)));
	}
}

function* deleteDesignSaga(
	action: PayloadAction<number>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();

		yield call(fetch, `${apiUrl}/designs/userdesign/${action.payload}/`, {
			method: "DELETE",
			headers: headers,
			credentials: "include",
		});
		yield put(deleteDesignSuccess(action.payload.toString()));
	} catch (e: unknown) {
		yield put(showErrorModal(String(e)));
	}
}

export const orderSlice = createSlice({
	name: "design",
	initialState,
	reducers: {
		getDesignRequest: (state) => {
			state.error = initialState.error;
			state.loading = true;
		},
		getDesignSuccess: (state, action: PayloadAction<UserDesign[]>) => {
			state.data = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		getDesignFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		deleteDesignRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
		},
		deleteDesignSuccess: (state, action: PayloadAction<string>) => {
			state.data = state.data.filter(
				(item) => !(item.id === Number(action.payload))
			);
			state.loaded = true;
			state.loading = false;
		},
		deleteDesignFailure: (state) => {
			state.loaded = true;
			state.loading = false;
		},
		addDesign(state, action: PayloadAction<UserDesign>) {
			state.selected.push(action.payload);
		},
		removeDesign(state, action: PayloadAction<UserDesign>) {
			if (state.selected) {
				state.selected = state.selected.filter(
					(design) => design.id !== action.payload.id
				);
			}
		},
		clearSelectedDesigns(state) {
			state.selected = [];
		},
	},
});

// TODO define actions better in actions.tsx
export const {
	getDesignRequest,
	getDesignSuccess,
	getDesignFailure,
	deleteDesignRequest,
	deleteDesignSuccess,
	deleteDesignFailure,
	addDesign,
	removeDesign,
	clearSelectedDesigns,
} = orderSlice.actions;

export default orderSlice.reducer;

export function* sagas() {
	yield takeEvery(DesignActionEnum.GET_DESIGN_REQUEST, getDesignSaga);
	yield takeEvery(DesignActionEnum.DELETE_DESIGN_REQUEST, deleteDesignSaga);
}
