import { TabEnum } from "../beans";

export const tabs = [
	{
		id: TabEnum.fabric,
		label: "tabs.fabric",
		url: "/fabbric",
		selected: true,
	},

	{
		id: TabEnum.print,
		label: "tabs.print",
		url: "/print",
	},
	// {
	// 	id: TabEnum.label,
	// 	label: "tabs.label",
	// 	url: "/label",
	// },
	{
		id: TabEnum.summary,
		label: "tabs.summary",
		url: "/summary",
	},
];
