import React  from 'react';
import { loadingLogo } from "../../constants/icons";
import Icon from "../Icon";
import { Wrap } from "./styles";

type Props = {
  size?: string;
};
const LoadingProduct = (props: Props) => {
  return (
    <Wrap className={`product-image ${props.size}`}>
      <div className="card animate">
        <Icon icon={loadingLogo} />
      </div>
    </Wrap>
  );
};

export default LoadingProduct;
