import styled from "styled-components";

export const Wrap = styled.div<{ $active?: boolean; $sticky?: boolean }>`
	position: ${(props) => (props.$sticky ? "sticky" : "static")};
	top: 76px;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	background: var(--white);
	width: 100%;
	box-sizing: border-box;
	.card-header {
		box-sizing: border-box;
		display: flex;
		align-items: center;
		border-bottom: 1px solid var(--purple30);
		padding: 16px 24px;
		height: 44px;

		.card-title {
			color: var(--gray130);
			font-weight: ${(props) => (props.$active ? "bold" : "normal")};
			width: 100%;
			display: flex;
			gap: 24px;
			justify-content: space-between;
			align-items: center;
			.title {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;
			}
			.action {
				display: flex;
				gap: 8px;
				button {
					svg {
						path {
							fill: var(--gray90);
						}
					}
				}
			}
		}
	}
	.card-container {
		box-sizing: border-box;
		display: block;
		padding: 16px;
		display: flex;
		flex-direction: column;
	}
`;
