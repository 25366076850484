import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
	.order-card {
		display: flex;
		flex-direction: column;
		border-radius: 4px;
		background: var(--white);
		width: 100%;
		.order-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 16px 24px;
			border-bottom: 1px solid var(--purple30);
			.order-details {
				align-self: flex-start;
				display: flex;
				gap: 40px;
				.order-placed,
				.order-total {
					display: flex;
					flex-direction: column;
					gap: 8px;
					.title-sm {
						color: var(--gray130);
					}
				}
			}
			.order-actions {
				text-align: right;
				display: flex;
				flex-direction: column;
				gap: 8px;
			}
		}
		.order-production-details {
			box-sizing: border-box;

			padding: 24px;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			gap: 40px;
			.order-production-actions {
				display: flex;
				flex-direction: column;
				gap: 24px;

				.title-lg {
					color: var(--gray130);
				}
			}
		}

		.production-designs-actions {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%;
			.sizes-container {
				margin-top: 0;
			}
			.actions {
				flex-direction: row;
				gap: 16px;
				justify-content: space-between;
			}
		}
	}
	@media only screen and (max-width: ${breakpoints.mobile}px) {
		.order-card {
			.order-header {
				flex-direction: column;
				justify-content: flex-start;
				align-items: baseline;
				gap: 8px;
				padding: 16px;
				.order-details {
					flex-direction: column;
					gap: 8px;
					.order-placed,
					.order-total {
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
					}
				}
				.order-actions {
					flex-direction: row;
					align-items: baseline;
					justify-content: space-between;
					width: 100%;
				}
			}
			.order-production-details {
				flex-direction: column;
				gap: 16px;
				padding: 16px;
			}
			.production-designs-actions {
				.actions {
					flex-direction: column;
					gap: 8px;
				}
			}
		}
	}

	.sizes-container {
		gap: 8px;
	}
`;
