import { useEffect } from "react";

const Hubspot = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "//js-eu1.hs-scripts.com/26490882.js";
		script.id = "hs-script-loader";
		script.async = true;
		script.defer = true;

		document.body.appendChild(script);
	}, []);

	return null;
};
export default Hubspot;
