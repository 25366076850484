import React from "react";
import { Wrap } from "./styles";
import { TabEnum } from "../../beans";
import Tab from "../Tab";
import PaddingManager from "../PaddingManager";
import SubsectionTitle from "../SubsectionTitle";
import Fabric from "../Fabric";
import Print from "../Print";
import { tabs } from "../../constants/tabs";
import { selectTab } from "../../store/UI";
import Summary from "../Summary";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import LabelOptions from "../LabelOptions";
import { useTranslation } from "react-i18next";

type Props = {
	sticky?: boolean;
};

const TabMenu = (props: Props) => {
	const { t } = useTranslation();
	const { sticky } = props;
	const tabSelected = useSelector((state: RootState) => state.ui.tab.selected);
	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const fabrics = useSelector((state: RootState) => state.fabrics.data);
	const selectedFabric = useSelector(
		(state: RootState) => state.fabrics.selectedFabric
	);
	const selectedFabricColor = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.col_fabric
	);

	const dispatch = useDispatch<AppDispatch>();

	const handleTabClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		dispatch(selectTab((e.target as Element).id));
		getTabPanelContent();
	};

	const getTabPanelContent = () => {
		let content = null;

		switch (tabSelected) {
			case TabEnum.fabric:
				content = renderFabric();
				break;
			case TabEnum.print:
				content = renderPrint();
				break;
			case TabEnum.label:
				content = renderLabel();
				break;
			case TabEnum.summary:
				content = renderSummary();
				break;
			default:
				content = renderDefault();
		}

		return content;
	};

	const renderDefault = () => {
		return <SubsectionTitle title="default" />;
	};

	const renderSummary = () => {
		return <Summary />;
	};

	const renderFabric = () => {
		if (fabrics && selectedFabric && selectedFabricColor && userDesign) {
			return <Fabric design={userDesign} />;
		} else {
			return null;
		}
	};

	const renderLabel = () => {
		return <LabelOptions />;
	};

	const renderPrint = () => {
		return <Print />;
	};
	// TODO nothing
	return tabs ? (
		<Wrap $sticky={sticky}>
			<div className="tab-menu" role="tablist">
				{tabs.map((tab, k) => {
					return (
						<Tab
							url={tab.url}
							selected={tab.id === tabSelected}
							onClick={handleTabClick}
							key={k}
							id={`${tab.id}`}
						>
							{t(tab.label)}
						</Tab>
					);
				})}
			</div>
			<div className="tab-panel">
				<PaddingManager>
					<div>{getTabPanelContent()}</div>
				</PaddingManager>
			</div>
		</Wrap>
	) : null;
};

export default TabMenu;
