import React, { useState } from "react";
import { Wrap } from "./styles";
import Button from "../Button";
import Divider from "../Divider";
import { ButtonEnum, UserDesign } from "../../beans";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import {
	clearSelectedDesigns,
	deleteDesignRequest,
	removeDesign,
} from "../../store/Design";
import {
	hideActionModal,
	resetActionModal,
	showActionModal,
} from "../../store/UI";
import { useTranslation } from "react-i18next";
import { addToOrderRequest } from "../../store/Order";

type Props = {
	selectedDesigns: UserDesign[];
};

const BottomBar = (props: Props) => {
	const { selectedDesigns } = props;
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const [cartButtonLoading, setCartButtonLoading] = useState(false);
	const order = useSelector((state: RootState) => state.order.data);

	const deleteDesigns = () => {
		if (selectedDesigns.length === 0) return;
		selectedDesigns.forEach((design) => {
			dispatch(deleteDesignRequest(design.id));
			dispatch(removeDesign(design));
			dispatch(resetActionModal());
		}, []);
	};

	const addToCart = () => {
		if (selectedDesigns.length === 0) return;
		const designIds: number[] = [];
		selectedDesigns.forEach((design) => {
			designIds.push(design.id);
		});
		order &&
			dispatch(addToOrderRequest({ orderId: order.id, design: designIds }));
	};

	const openDeleteModal = () => {
		dispatch(
			showActionModal({
				title: t("designPreview.deleteModal.title"),
				error: `<div class="title-sm">${t("designPreview.deleteModal.contenTitle", { count: selectedDesigns.length })}</div><p class="label-lg">${t("designPreview.deleteModal.contenDescription", { count: selectedDesigns.length })}</p>`,
				actions: [
					{
						style: ButtonEnum.secondary,
						onClick: () => dispatch(hideActionModal()),
						label: t("designPreview.deleteModal.keep"),
					},
					{
						style: ButtonEnum.danger,
						onClick: () => deleteDesigns(),
						label: t("designPreview.deleteModal.delete"),
					},
				],
			})
		);
	};

	return (
		<Wrap>
			<div className="count title-lg">{selectedDesigns.length} selected</div>
			<Divider vertical />
			<div className="actions">
				<Button
					style={ButtonEnum.secondary}
					onClick={() => {
						dispatch(clearSelectedDesigns());
					}}
				>
					{t("button.clear")}
				</Button>

				<Button
					style={ButtonEnum.danger}
					onClick={() => {
						openDeleteModal();
					}}
				>
					{t("button.delete")}
				</Button>
				<Button
					style={ButtonEnum.primary}
					loading={cartButtonLoading}
					onClick={() => {
						setCartButtonLoading(true);
						addToCart();
					}}
				>
					{t("button.addToCart")}
				</Button>
			</div>
		</Wrap>
	);
};

export default BottomBar;
