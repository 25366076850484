import styled from "styled-components";

export const Wrap = styled.div<{ $selected?: boolean; $disabled?: boolean }>`
	#lang {
		position: relative;
		justify-content: end;
		display: flex;
		align-items: center;
		gap: 8px;
		white-space: nowrap;
		height: 50px;
		.lang {
			text-transform: uppercase;
		}
	}
	#logged:hover {
		.logged-actions {
			display: flex;
		}
	}
	#lang:hover {
		.lang-actions {
			display: flex;
		}
	}
`;
