import { getCookie } from "../utils/cookies";

export const getAuthHeader = () => {
  const token = getCookie("token");
  let headers: { [key: string]: string } = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (token) headers = { ...headers, Authorization: "Token " + token };
  return headers;
};
