import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	call,
	put,
	StrictEffect,
	takeEvery,
	takeLeading,
} from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import { Order, OrderConfirmation, OrderList, OrderSummary } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { getErrors } from "../../utils/errors";
import { showErrorModal } from "../UI";
import { CreateOrderActionEnum } from "../../store/Order/actions";
import { goToOrderProduction } from "../../constants/routes";

export interface OrderState {
	data: null | Order; // TODO type better
	summary: null | OrderSummary;
	confirmation: null | OrderConfirmation;
	orderList: OrderList[] | null;
	error: null | string;
	loadingSummary: boolean;
	loadingCart: boolean;
	loading: boolean;
	loaded: boolean;
}

const initialState: OrderState = {
	data: null,
	error: null,
	summary: null,
	orderList: null,
	confirmation: null,
	loadingSummary: false,
	loadingCart: false,
	loading: false,
	loaded: false,
};

// SAGAS
function* createOrderSaga(
	action: PayloadAction<string>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(fetch, `${apiUrl}/orders/orders/`, {
			method: "POST",
			body: JSON.stringify({
				design: action.payload,
			}),
			headers: headers,
			credentials: "include",
		});

		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(createOrderSuccess(data));
			window.location.assign(goToOrderProduction(data.id));
		} else {
			yield put(createOrderFailure(data));
		}
	} catch (error: unknown) {
		// console.error("ERROR", error);
	}
}

function* addToOrderSaga(
	action: PayloadAction<{ orderId: string; design: number[] }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/orders/orders/${action.payload.orderId}/add_item/`,
			{
				method: "POST",
				body: JSON.stringify({
					design: action.payload.design,
				}),
				headers: headers,
				credentials: "include",
			}
		);

		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(addToOrderSuccess(data));
			window.location.assign(goToOrderProduction(data.id));
		} else {
			yield put(addToOrderFailure(data));
		}
	} catch (error: unknown) {
		// console.error("ERROR", error);
	}
}

function* cloneToOrderSaga(
	action: PayloadAction<{ orderId: string; design: number }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/designs/userdesign/${action.payload.design}/clone/`,
			{
				method: "POST",
				body: JSON.stringify({}),
				headers: headers,
				credentials: "include",
			}
		);

		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(
				addToOrderRequest({
					orderId: action.payload.orderId,
					design: [data.id],
				})
			);
		} else {
			yield put(addToOrderFailure(data));
		}
	} catch (error: unknown) {
		// console.error("ERROR", error);
	}
}

function* removeFromOrderSaga(
	action: PayloadAction<{ orderId: string; item: number }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/orders/orders/${action.payload.orderId}/remove-item/`,
			{
				method: "POST",
				body: JSON.stringify({
					order_item_id: action.payload.item,
				}),
				headers: headers,
				credentials: "include",
			}
		);

		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			// yield put(removeFromOrderSuccess(data));
			yield put(getCurrentCartRequest());
			yield put(getOrderSummaryRequest(action.payload.orderId));
		} else {
			yield put(removeFromOrderFailure(data));
		}
	} catch (error: unknown) {
		// console.error("ERROR", error);
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* getCurrentCartSaga(): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/orders/orders/current_order/`,
			{
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();

		if (response.ok) {
			yield put(getCurrentCartSuccess(data));
		} else {
			yield put(getCurrentCartFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		// console.error("ORDER ERRORS", error);
		const errorMessage =
			"The server encountered an unexpected problem. Try again";
		yield put(getOrderByIdFailure(errorMessage));
		yield put(showErrorModal(errorMessage));
	}
}

function* getOrderByIdSaga(
	action: PayloadAction<string>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/orders/orders/${action.payload}/`,
			{
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();

		if (response.ok) {
			yield put(getOrderByIdSuccess(data));
		} else {
			yield put(getOrderByIdFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		// console.error("ORDER ERRORS", error);
		const errorMessage =
			"The server encountered an unexpected problem. Try again";
		yield put(getOrderByIdFailure(errorMessage));
		yield put(showErrorModal(errorMessage));
	}
}

function* getOrderCompletedSaga(
	action: PayloadAction<string>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/orders/orders/${action.payload}/confirmation/`,
			{
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();

		if (response.ok) {
			yield put(getOrderCompletedSuccess(data));
			yield put(createOrderSuccess(data));
		} else {
			yield put(getOrderCompletedFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		// console.error("ORDER ERRORS", error);
		const errorMessage =
			"The server encountered an unexpected problem. Try again";
		yield put(getOrderCompletedFailure(errorMessage));
		yield put(showErrorModal(errorMessage));
	}
}

function* getOrderSummarySaga(
	action: PayloadAction<{ orderId: string; code: string }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();

		const response = yield call(
			fetch,
			`${apiUrl}/orders/orders/${action.payload}/summary/`,
			{
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();
		if (response.status === 200 || response.status === 201) {
			yield put(getOrderSummarySuccess(data));
		} else {
			yield put(getOrderSummaryFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		// console.error("ORDER ERRORS", error);
		const errorMessage =
			"The server encountered an unexpected problem. Try again";
		yield put(getOrderSummaryFailure(errorMessage));
		yield put(showErrorModal(errorMessage));
	}
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* listOrderSaga(): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(fetch, `${apiUrl}/orders/orders/`, {
			headers: headers,
			credentials: "include",
		});

		const data = yield response.json();

		if (response.ok) {
			yield put(listOrderSuccess(data));
		} else {
			yield put(listOrderFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		// console.error("ERROR", error);
	}
}

function* applyCouponSaga(
	action: PayloadAction<{ orderId: string; code: string }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/orders/orders/${action.payload.orderId}/apply_coupon/`,
			{
				method: "POST",
				body: JSON.stringify({
					code: action.payload.code,
				}),
				headers: headers,
				credentials: "include",
			}
		);

		const data = yield response.json();

		if (response.ok) {
			yield put(applyCouponSuccess(data));
		} else {
			yield put(applyCouponFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		console.error("ERROR", error);
		yield put(
			showErrorModal(getErrors(error as Record<string, string | string[]>))
		);
	}
}

function* removeCouponSaga(
	action: PayloadAction<{ orderId: string }>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/orders/orders/${action.payload}/remove_coupon/`,
			{
				method: "POST",
				headers: headers,
				credentials: "include",
			}
		);

		const data = yield response.json();

		if (response.ok) {
			yield put(removeCouponSuccess(data));
		} else {
			yield put(removeCouponFailure(data));
			yield put(showErrorModal(getErrors(data)));
		}
	} catch (error: unknown) {
		// console.error("ERROR", error);
	}
}

export const orderSlice = createSlice({
	name: "order",
	initialState,

	reducers: {
		resetOrders: () => initialState,
		listOrderRequest: (state) => {
			state.error = initialState.error;
			state.loading = true;
		},
		listOrderSuccess: (state, action: PayloadAction<OrderList[]>) => {
			state.orderList = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		listOrderFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		createOrderRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
		},
		createOrderSuccess: (state, action: PayloadAction<Order>) => {
			state.data = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		createOrderFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		addToOrderRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
		},
		addToOrderSuccess: (state, action: PayloadAction<Order>) => {
			state.data = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		addToOrderFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		cloneToOrderRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
		},
		cloneToOrderSuccess: (state, action: PayloadAction<Order>) => {
			state.data = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		cloneToOrderFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		removeFromOrderRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
		},
		removeFromOrderSuccess: (state, action: PayloadAction<Order>) => {
			state.data = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		removeFromOrderFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getOrderByIdRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
			state.loaded = false;
		},
		getOrderByIdSuccess: (state, action: PayloadAction<Order>) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		getOrderByIdFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getOrderSummaryRequest: (state, _action) => {
			state.error = initialState.error;
			state.loading = true;
			state.loadingSummary = true;
		},
		getOrderSummarySuccess: (state, action: PayloadAction<OrderSummary>) => {
			state.summary = action.payload;
			state.loading = false;
			state.loadingSummary = false;

			state.loaded = true;
		},
		getOrderSummaryFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loadingSummary = false;

			state.loading = false;
		},

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getOrderCompletedRequest: (state, _action: PayloadAction<string>) => {
			state.error = initialState.error;
			state.loading = true;
		},
		getOrderCompletedSuccess: (
			state,
			action: PayloadAction<OrderConfirmation>
		) => {
			state.confirmation = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		getOrderCompletedFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		applyCouponRequest: (state, _action) => {
			state.error = initialState.error;
		},
		applyCouponSuccess: (state, action: PayloadAction<OrderSummary>) => {
			state.error = initialState.error;
			state.summary = action.payload;
		},
		applyCouponFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		removeCouponRequest: (state, _action) => {
			state.error = initialState.error;
		},
		removeCouponSuccess: (state, action: PayloadAction<OrderSummary>) => {
			state.error = initialState.error;
			state.summary = action.payload;
		},
		removeCouponFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		getCurrentCartRequest: (state) => {
			state.error = initialState.error;
			state.loading = true;
			state.loadingCart = true;
		},
		getCurrentCartSuccess: (state, action: PayloadAction<Order>) => {
			state.data = action.payload;
			state.loading = false;
			state.loadingCart = false;
		},

		getCurrentCartFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loadingCart = false;
		},
	},
});

// TODO define actions better in actions.tsx
export const {
	resetOrders,
	listOrderRequest,
	listOrderSuccess,
	listOrderFailure,
	createOrderRequest,
	createOrderSuccess,
	createOrderFailure,
	addToOrderRequest,
	addToOrderSuccess,
	addToOrderFailure,
	cloneToOrderRequest,
	cloneToOrderSuccess,
	cloneToOrderFailure,
	removeFromOrderRequest,
	removeFromOrderSuccess,
	removeFromOrderFailure,
	getCurrentCartRequest,
	getCurrentCartSuccess,
	getCurrentCartFailure,
	getOrderByIdRequest,
	getOrderByIdSuccess,
	getOrderByIdFailure,
	getOrderSummaryRequest,
	getOrderSummarySuccess,
	getOrderSummaryFailure,
	getOrderCompletedRequest,
	getOrderCompletedSuccess,
	getOrderCompletedFailure,
	applyCouponRequest,
	applyCouponSuccess,
	applyCouponFailure,
	removeCouponRequest,
	removeCouponSuccess,
	removeCouponFailure,
} = orderSlice.actions;

export default orderSlice.reducer;

export function* sagas() {
	yield takeEvery(CreateOrderActionEnum.CREATE_ORDER_REQUEST, createOrderSaga);
	yield takeEvery(addToOrderRequest, addToOrderSaga);
	yield takeEvery(cloneToOrderRequest, cloneToOrderSaga);

	yield takeEvery(removeFromOrderRequest, removeFromOrderSaga);
	yield takeEvery(getCurrentCartRequest, getCurrentCartSaga);

	// yield takeEvery(CreateOrderActionEnum.GET_ORDER_BY_ID_REQUEST, getOrderById);
	yield takeLeading(
		CreateOrderActionEnum.GET_ORDER_SUMMARY_REQUEST,
		getOrderSummarySaga
	);
	yield takeEvery(
		CreateOrderActionEnum.GET_ORDER_COMPLETED_REQUEST,
		getOrderCompletedSaga
	);
	yield takeEvery(CreateOrderActionEnum.LIST_ORDER_REQUEST, listOrderSaga);
	yield takeEvery(CreateOrderActionEnum.APPLY_COUPON_REQUEST, applyCouponSaga);
	yield takeEvery(
		CreateOrderActionEnum.REMOVE_COUPON_REQUEST,
		removeCouponSaga
	);
	yield takeEvery(getOrderByIdRequest, getOrderByIdSaga);
}
