import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
export const Wrap = styled.div<{ $open: boolean }>`
	width: 100%;
	position: relative;
	right: 0;
	/* background: var(--white); */
	height: 50px;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.item-action {
		display: flex;
		width: max-content;
	}
	.items {
		display: ${(props) => (props.$open ? "flex" : "none")};
		flex-direction: column;
		align-items: end;
		background: var(--white);
		position: absolute;
		right: 0;
		top: 50px;
		padding: 8px;
		border-radius: 4px;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
		flex-direction: column;
		align-items: start;
		justify-content: center;
		min-width: 200px;
		max-width: 300px;
		width: max-content;
	}

	@media only screen and (max-width: ${breakpoints.tablet}px) {
		width: 50px;

		.item-action {
			button {
				text-indent: -9999px;
			}
		}
	}
`;
