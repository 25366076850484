import React from "react";
import Icon from "../Icon";
import { Wrap } from "./styles";
import { loadingCircle } from "../../constants/icons";

const LoadingText = () => {
	return (
		<Wrap>
			<Icon clean icon={loadingCircle} />
		</Wrap>
	);
};

export default LoadingText;
