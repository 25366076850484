import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  display: flex;
  height: 100vh;
  position: relative;

  .lang-switcher {
    position: absolute;
    top: 0px;
    right: 20px;
    margin: auto;
  }
  .padding-manager {
    padding: 80px 40px;
    justify-content: center;
  }
  .heading,
  .form {
    width: 50%;
    display: flex;
    padding: 40px 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
  }

  .form {
    .form-container {
      max-width: 480px;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
  }

  .heading {
    background: var(--gray20);
    .heading-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      max-width: 480px;
      align-items: center;
      justify-content: center;
      margin: auto;

      .heading-image {
        img {
          max-width: 100%;
        }
      }
      .heading-text {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .title-lg,
        .title-sm {
          color: var(--gray130);
        }
        .list-item {
          margin-left: 28px;
          margin-bottom: 24px;
        }
        .list-title {
          position: relative;
          &::before {
            content: "";
            display: block;
            position: absolute;
            left: -28px;
            width: 20px;
            height: 20px;
            background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2220%22%20height%3D%2221%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M16.2458%203.23643C16.4305%203.12535%2016.651%203.09013%2016.8611%203.13814C17.0711%203.18615%2017.2544%203.31367%2017.3725%203.49393L18.1975%204.75226C18.3051%204.91665%2018.3511%205.11374%2018.3274%205.30875C18.3038%205.50377%2018.212%205.68416%2018.0683%205.8181L18.0658%205.82143L18.0542%205.83226L18.0067%205.87643L17.8192%206.0556C16.7817%207.06198%2015.7757%208.10026%2014.8025%209.16893C12.9717%2011.1823%2010.7975%2013.8173%209.33418%2016.3739C8.92584%2017.0873%207.92834%2017.2406%207.33668%2016.6248L1.93251%2011.0098C1.85506%2010.9293%201.79459%2010.834%201.75469%2010.7297C1.71479%2010.6254%201.69627%2010.5141%201.70023%2010.4024C1.7042%2010.2908%201.73056%2010.1811%201.77777%2010.0799C1.82497%209.97862%201.89205%209.8879%201.97501%209.8131L3.60834%208.33976C3.75188%208.21036%203.93593%208.13486%204.12899%208.12619C4.32205%208.11753%204.51213%208.17623%204.66668%208.29226L7.42418%2010.3598C11.7317%206.11226%2014.1742%204.48226%2016.2458%203.23643Z%22%20fill%3D%22black%22%2F%3E%3C%2Fsvg%3E");
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .heading,
    .form {
      padding: 0;
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    .heading {
      display: none;
    }
    .form {
      width: 100%;
    }
  }
`;
