const productionPattern = "/order/:orderId/";

const shippinpgPattern = "/order/:orderId/shipping/";

const checkoutPattern = "/order/:orderId/checkout/";

const designPattern = "/design/edit/:designId/";

const orderCompletedPattern = "/order/:orderId/completed/";

export const routes = {
	home: "/",
	newUser: "/welcome",
	access: "/access",
	design: "/design/",
	production: "/production",
	passwordReset: "/password/reset/",
};

export const goToEditDesign = (designId: number) => {
	return `/design/edit/${designId}/`;
};

export const goToOrderProduction = (orderId: number) => {
	return `/order/${orderId}/`;
};

export const goToOrderShipping = (orderId: string) => {
	return `/order/${orderId}/shipping/`;
};

export const goToOrderCheckout = (orderId: string) => {
	return `/order/${orderId}/checkout/`;
};

/// CHECKS
export const isNewUserRoute = (route: string) => {
	return isRouteMatchingPattern(route, "/welcome");
};

export const isDesignRoute = (route: string) => {
	return isRouteMatchingPattern(route, designPattern);
};

export const isProductionRoute = (route: string) => {
	return isRouteMatchingPattern(route, productionPattern);
};

export const isShippingRoute = (route: string) => {
	return isRouteMatchingPattern(route, shippinpgPattern);
};

export const isCheckoutRoute = (route: string) => {
	return isRouteMatchingPattern(route, checkoutPattern);
};

export const isOrderCompletedRoute = (route: string) => {
	return isRouteMatchingPattern(route, orderCompletedPattern);
};

/// UTILS

function isRouteMatchingPattern(route: string, pattern: string) {
	const routeSegments = route.split("/");
	const patternSegments = pattern.split("/");

	//check if have same segments number
	if (routeSegments.length !== patternSegments.length) {
		return false;
	}

	// check every segment
	for (let i = 0; i < patternSegments.length; i++) {
		if (patternSegments[i].startsWith(":")) {
			// Il segmento del pattern è un parametro dinamico, salta il confronto
			continue;
		}
		if (routeSegments[i] !== patternSegments[i]) {
			return false;
		}
	}

	return true;
}
