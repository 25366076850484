import styled from "styled-components";
import { rotate, loading } from "../../design-system/animations";

export const Wrap = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  color: transparent;
  background: linear-gradient(
    100deg,
    var(--gray20) 30%,
    var(--gray30) 50%,
    var(--gray20) 70%
  );
  background-size: 400%;
  background-position: 100% 50%;
  animation: ${loading} 1.8s ease-in-out infinite;

  svg {
    animation: ${rotate} 2s linear infinite;
    fill: #7745ff;
    path {
      fill: #7745ff;
    }
  }
`;
