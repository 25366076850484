import React, { useEffect } from "react";
import Header from "../../components/Header";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import {
	ButtonEnum,
	ButtonTypeEnum,
	OrderItem as OrderItemBean,
} from "../../beans";
import Card from "../../components/Card";

import PaddingManager from "../../components/PaddingManager";

import { Wrap } from "./styles";
import { routes } from "../../constants/routes";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";

import Icon from "../../components/Icon";
import {
	emptyOrderPage,
	externalLink,
	plusButton,
	phone,
	questionBaloon,
} from "../../constants/icons";
import {
	getCurrentCartRequest,
	getOrderSummaryRequest,
} from "../../store/Order";
import LoadingOptions from "../../components/LoadingOptions";
import ProductionSummary from "../../components/ProductionSummary";
import { getProductionRangeAvailability } from "../../store/ProductionRange";
import OrderItem from "../../components/OrderItem";

const OrderCart = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const { t } = useTranslation();

	const { orderId } = useParams();

	// GLOBAL STATE
	const summary = useSelector((state: RootState) => state.order.summary);
	const order = useSelector((state: RootState) => state.order.data);
	const productionRange = useSelector(
		(state: RootState) => state.productionRange.data
	);
	const availableRange = useSelector(
		(state: RootState) => state.productionRange.availableRange
	);

	// HOOKS
	useEffect(() => {
		dispatch(getOrderSummaryRequest(orderId));
		dispatch(getCurrentCartRequest());
	}, [orderId, dispatch]);

	useEffect(() => {
		if (availableRange.length === 0) dispatch(getProductionRangeAvailability());
	}, [availableRange.length, dispatch, orderId, productionRange.length]);

	useEffect(() => {
		if (!orderId) {
			navigate(routes.home);
		}
	}, [dispatch, navigate, orderId]);

	const isCartEmpty = () => {
		return order?.orderitem_set?.length === 0;
	};

	return (
		<>
			<Header product onClose={() => navigate(routes.home)} />{" "}
			<Wrap $empty={isCartEmpty()}>
				<PaddingManager>
					<div className="card-containers">
						<div className="production-options">
							{order?.id ? (
								<>
									{order.orderitem_set.map((orderItem: OrderItemBean) => (
										<OrderItem
											designId={orderItem.design.id}
											orderId={order?.id}
											orderItem={orderItem}
											key={orderItem.id}
										/>
									))}
								</>
							) : (
								<Card>
									<LoadingOptions />
								</Card>
							)}
							{!isCartEmpty() && (
								<Button
									style={ButtonEnum.secondary}
									onClick={() => navigate(routes.design)}
								>
									<Icon icon={plusButton} info />
									{t("orderCart.addProduct")}
								</Button>
							)}
							{isCartEmpty() && (
								<Card>
									<div className="empty-page">
										<Icon icon={emptyOrderPage} />
										<div>
											<div className="heading-md">
												{t("orderCart.noProducts")}
											</div>
											<div className="title-lg">{t("orderCart.products")}</div>
										</div>
										<Button
											style={ButtonEnum.secondary}
											onClick={() => navigate(routes.design)}
										>
											<Icon icon={plusButton} />
											{t("orderCart.addProduct")}
										</Button>
									</div>
								</Card>
							)}
							<div className="help-container">
								<Card>
									<div>
										<Icon icon={phone} />
									</div>
									<div className="title-sm">
										{t("orderProduction.helpAssistanceTitle")}
									</div>
									<div
										className="label-lg"
										dangerouslySetInnerHTML={{
											__html: t("orderProduction.helpAssistanceDescription"),
										}}
									/>

									<div className="label-lg">
										<Button
											type={ButtonTypeEnum.button}
											style={ButtonEnum.link}
											onClick={() =>
												window.open(
													"https://fabbric.com/pages/solicita-una-llamada-con-un-experto",
													"_blank"
												)
											}
										>
											{t("orderProduction.helpAssistanceButton")}
											<Icon icon={externalLink} />
										</Button>
									</div>
								</Card>
								<Card>
									<div>
										<Icon icon={questionBaloon} />
									</div>
									<div className="title-sm">
										{t("orderProduction.helpAnswerTitle")}
									</div>
									<div className="label-lg">
										{t("orderProduction.helpAnswerDescription")}
									</div>
									<div className="label-lg">
										<Button
											type={ButtonTypeEnum.button}
											style={ButtonEnum.link}
											onClick={() => {
												window.open(
													"https://help-center.fabbric.com/knowledge/fabbric-studio",
													"_blank"
												);
											}}
										>
											{t("orderProduction.helpAnswerButton")}{" "}
											<Icon icon={externalLink} />
										</Button>
									</div>
								</Card>
							</div>
						</div>

						{!isCartEmpty() && (
							<ProductionSummary summary={summary} orderId={orderId} />
						)}
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default OrderCart;
