import { keyframes } from "styled-components";

export const loading = keyframes`
   from {
    background-position: 100% 50%;
  }
  to{
    background-position: 0 50%;
  }
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const appearFromBottom = keyframes`
  from {
    bottom: -100px;
  }
  to {
    bottom: 20px;
  }
`;

export const appearThumb = keyframes`
  0% {
    scale: .3;
  }
  95%{
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
`;
