import React from "react";
import { MouseEventHandler } from "react";
import { ButtonEnum, Item } from "../../beans";
import Button from "../Button";

import ProductImage from "../ProductImage";
import { Wrap } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
	size?: string;
	item: Item;
	onClick?: MouseEventHandler<HTMLDivElement>;
};
const ProductCard = (props: Props) => {
	const { name, id, default_render } = props.item;
	const { t } = useTranslation();

	return (
		<Wrap
			onClick={props.onClick}
			key={id}
			className={props.size}
			id={`product_${id}`}
		>
			<div className="product-image-container">
				{default_render && <ProductImage src={default_render} alt={name} />}
				<Button style={ButtonEnum.primary}>{t("productCard.select")}</Button>
			</div>
			<div className="product-label title-sm">{name}</div>
		</Wrap>
	);
};

export default ProductCard;
