import styled from "styled-components";

export const Wrap = styled.div`
  display: inline-flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 300px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  border: 1px solid var(--purple100);
  border-radius: 4px;
  padding: 20px;

  .request-box {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 300px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .description {
      color: var(--purple100);
      text-align: center;
    }
    svg {
      path {
        fill: var(--purple100);
      }
    }
  }
  /* .action-container {
    position: absolute;
    bottom: 20px;
  } */
`;
