import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { call, put, StrictEffect, takeEvery } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";
import {
	InfoBox,
	ModalEnum,
	TabEnum,
	ModalActionButton,
	CageSize,
} from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { MouseEventHandler, ReactElement } from "react";

export interface RegistrationState {
	infoBox: {
		open: boolean;
		active: InfoBox;
		list: InfoBox[];
		loading: boolean;
		loaded: boolean;
		error: string | null;
	};
	access: {
		login: boolean;
		register: boolean;
	};
	modal: {
		open: boolean;
		content: ReactElement | null;
		type: ModalEnum | null;
		title: string;
		actions: ModalActionButton[];
		onClose: MouseEventHandler<HTMLButtonElement> | null;
	};
	errorModal: {
		open: boolean;
		error: string;
	};
	actionModal: {
		open: boolean;
		error: string;
		type: ModalEnum;
		title: string;
		actions: ModalActionButton[];
		onClose: MouseEventHandler<HTMLButtonElement> | null;
	};
	tab: {
		tabs: [TabEnum.fabric, TabEnum.print, TabEnum.summary];
		selected: TabEnum.fabric;
	};
	cages?: CageSize[];
}
const cageSize: CageSize[] = [];

const initialState = {
	infoBox: {
		open: false,
		active: {
			content: "No info available",
			image: "",
			title: "Info",
		},
		list: [],
		loading: false,
		loaded: true,
		error: null,
	},
	access: { login: false, register: true },
	modal: {
		open: false,
		content: null,
		type: null,
		title: "",
		actions: [],
		onClose: null,
	},
	errorModal: {
		open: false,
		error: "",
	},
	actionModal: {
		open: false,
		error: "",
		type: ModalEnum.error,
		title: "",
		actions: [],
		onClose: null,
	},
	tab: {
		tabs: [TabEnum.fabric, TabEnum.print, TabEnum.summary],
		selected: TabEnum.fabric,
	},
	cages: cageSize,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* listInfoBoxContentsSaga(): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(fetch, `${apiUrl}/platform/platform_images/`, {
			headers: headers,
			credentials: "include",
		});

		if (response.status === 200 || response.status === 201) {
			const data = yield response.json();

			yield put(listInfoBoxContentsSuccess(data));
		} else {
			yield put(listInfoBoxContentsFailure(response));
		}
	} catch (error: unknown) {
		yield put(listInfoBoxContentsFailure(error));
	}
}

export const uiSlice = createSlice({
	name: "ui",
	initialState,
	reducers: {
		showFabricInfoBox: (state, action) => {
			state.infoBox.active = action.payload.active;
			state.infoBox.open = true;
		},
		showInfoBox: (state, action) => {
			state.infoBox.active =
				state.infoBox.list.find((item: InfoBox) => {
					return item.key === action.payload.key;
				}) || initialState.infoBox.active;

			state.infoBox.open = true;
		},
		hideInfoBox: (state) => {
			state.infoBox.open = initialState.infoBox.open;
			state.infoBox.active = initialState.infoBox.active;
		},
		listInfoBoxContentsRequest: (state) => {
			state.infoBox.loading = true;
			state.infoBox.loaded = false;
		},
		listInfoBoxContentsSuccess: (state, action) => {
			state.infoBox.list = action.payload;
			state.infoBox.loading = false;
			state.infoBox.loaded = true;
		},
		listInfoBoxContentsFailure: (state, action) => {
			state.tab.selected = action.payload;
			state.infoBox.loading = false;
			state.infoBox.loaded = true;
		},
		showAccessForm: (state, action) => {
			state.access = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		showModal: (state, action: PayloadAction<any>) => {
			state.modal.open = true;
			state.modal.content = action.payload.content;
			state.modal.title = action.payload.title;
			state.modal.actions = action.payload.actions;
		},
		hideModal: (state) => {
			state.modal.open = false;
			state.modal.content = null;
		},
		resetModal: (state) => {
			state.modal = initialState.modal;
		},
		showErrorModal: (state, action: PayloadAction<string>) => {
			state.errorModal.error = action.payload;
			state.errorModal.open = true;
		},
		hideErrorModal: (state) => {
			state.errorModal = initialState.errorModal;
		},
		resetErrorModal: (state) => {
			state.errorModal = initialState.errorModal;
		},
		showActionModal: (state, action) => {
			state.actionModal.error = action.payload.error;
			state.actionModal.title = action.payload.title;
			state.actionModal.actions = action.payload.actions;
			state.actionModal.open = true;
			if (action.payload.onClose)
				state.actionModal.onClose = action.payload.onClose;
		},
		hideActionModal: (state) => {
			state.actionModal = initialState.actionModal;
		},
		resetActionModal: (state) => {
			state.actionModal = initialState.actionModal;
		},
		selectTab: (state, action) => {
			state.tab.selected = action.payload;
		},
		nextTab: (state) => {
			const nextIndex =
				state.tab.tabs.indexOf(state.tab.selected) < state.tab.tabs.length
					? state.tab.tabs.indexOf(state.tab.selected)
					: 0;

			state.tab.selected = state.tab.tabs[nextIndex + 1];
		},
		prevTab: (state) => {
			const prevIndex =
				state.tab.tabs.indexOf(state.tab.selected) > 0
					? state.tab.tabs.indexOf(state.tab.selected)
					: state.tab.tabs.length;

			state.tab.selected = state.tab.tabs[prevIndex - 1];
		},
		resetTab: (state) => {
			state.tab = initialState.tab;
		},

		addCage: (state, action: PayloadAction<CageSize>) => {
			if (state.cages.length) {
				const index = state.cages.findIndex(
					(cage: CageSize) => cage.id === action.payload.id
				);
				if (index < 0) {
					state.cages.push(action.payload);
				} else {
					state.cages[index] = action.payload;
				}
			} else {
				state.cages.push(action.payload);
			}
		},
	},
});

export const {
	showFabricInfoBox,
	showInfoBox,
	hideInfoBox,
	showAccessForm,
	showModal,
	hideModal,
	resetModal,
	showErrorModal,
	hideErrorModal,
	resetErrorModal,
	showActionModal,
	hideActionModal,
	resetActionModal,
	selectTab,
	nextTab,
	prevTab,
	resetTab,
	listInfoBoxContentsRequest,
	listInfoBoxContentsSuccess,
	listInfoBoxContentsFailure,
	addCage,
} = uiSlice.actions;

export default uiSlice.reducer;
export function* sagas() {
	yield takeEvery(listInfoBoxContentsRequest.type, listInfoBoxContentsSaga);
}
