import styled from "styled-components";

export const Wrap = styled.div`
	height: 100%;
	min-height: 300px;

	.product-preview {
		height: 100%;
		min-height: 300px;
		position: relative;
		background: var(--gray20);
		border-radius: 4px;
		margin: auto;
		.print-type {
			position: absolute;
			left: 4px;
			bottom: 4px;
			padding: 4px;
			background: var(--gray30);
			border-radius: 4px;
			font-size: 12px;
			display: inline;
		}
		.product-image {
			object-fit: contain;
			box-sizing: border-box;
			max-width: 600px;
			width: 100%;
			max-height: calc(100vh - 100px);
		}
		.artwork-area {
			position: absolute;
			z-index: 2;

			svg {
				position: absolute;
			}
			img {
				transition: all 0.3s all ease-in-out;
			}

			&.front_center {
				width: 28%;
				height: 9.3%;
				top: 30%;
				left: 50%;
				transform: translate(-50%, -50%);

				&.item_014,
				&.item_015 {
					top: 33%;
				}
				&.item_016 {
					top: 31.5%;
				}

				img {
					position: absolute;
					object-fit: contain;
					max-width: 100%;
					max-height: 100%;
				}
			}

			.artwork-preview {
				position: absolute;
				z-index: 2;
				width: 100%;
				height: 55px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					object-fit: contain;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
	&.small {
		min-height: 100px;
		.product-preview {
			min-height: 100px;
		}
	}
`;
