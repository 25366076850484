import React  from 'react';
import { Wrap } from "./styles";

const LoadingOptions = () => {
  return (
    <Wrap>
      <div className="title animate"></div>
      <div className="blocks-container">
        <div className="block">
          <div className="title animate"></div>
          <div className="description animate"></div>
        </div>
        <div className="block">
          <div className="title animate"></div>
          <div className="description animate"></div>
        </div>
        <div className="block">
          <div className="title animate"></div>
          <div className="description animate"></div>
        </div>
      </div>
    </Wrap>
  );
};

export default LoadingOptions;
