import React from "react";
import { ForwardedRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppDispatch, RootState } from "../../store";
import { setProductionRequest } from "../../store/Production";

import { translateSize } from "../../utils/translate";
import { AlertEnum, Production, ProductionRange } from "../../beans";
import Alert from "../Alert";
import { Wrap } from "./styles";

import { sizeSelectorSchema, sizesSelectorForm } from "../../utils/form";
import { calculateTotalProductionItems } from "../../utils/orders";

import Divider from "../Divider";
import { priceFormat } from "../../utils/format";

import LoadingItem from "../LoadingItem";
import LoadingText from "../LoadingText";

export type Ref = ForwardedRef<HTMLFormElement>;

type Props = {
	orderId: number;
	designId: number;
	production: Production;
	selectedProductionRange: ProductionRange | null;
};

const SizeSelector = (props: Props) => {
	const dispatch = useDispatch<AppDispatch>();

	const { orderId, designId, production, selectedProductionRange } = props;

	const [total, setTotal] = useState(0);

	const minimum = useSelector(
		(state: RootState) => state.productionRange.selectedRange?.minimum
	);
	const orderState = useSelector((state: RootState) => state.order);
	const productionState = useSelector((state: RootState) => state.production);
	// HOOKS

	useEffect(() => {
		getTotal(getValues());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		reset(production.sizes as sizesSelectorForm);
		setFormData(production.sizes as sizesSelectorForm);

		getTotal(getValues());
	}, [selectedProductionRange, production]); // eslint-disable-line react-hooks/exhaustive-deps

	const [formData, setFormData] = useState<sizesSelectorForm>({
		x_small: production?.sizes?.x_small || 0,
		small: production?.sizes?.small || 0,
		medium: production?.sizes?.medium || 0,
		large: production?.sizes?.large || 0,
		x_large: production?.sizes?.x_large || 0,
	});

	const getTotal = (data: sizesSelectorForm): number => {
		const sum = calculateTotalProductionItems(data);
		setTotal(sum);
		return sum;
	};

	const handleInputChange = (data: sizesSelectorForm) => {
		setFormData(data);
		getTotal(data);

		if (production?.id && minimum) {
			if (total > minimum)
				dispatch(
					setProductionRequest({
						designId: designId,
						productionId: production?.id,
						orderId: orderId,
						body: formData,
					})
				);
		}
	};

	const setErrorClass = (k: keyof sizesSelectorForm) => {
		let errorClass = "";
		if (
			touchedFields[k as keyof sizesSelectorForm] &&
			errors[k as keyof sizesSelectorForm]
		)
			errorClass = "error";

		return errorClass;
	};

	const renderErrors = () => {
		return Object.keys(errors).map((key) => {
			const typedKey = key as keyof sizesSelectorForm;
			return (
				<Alert type={AlertEnum.error} key={typedKey}>
					{errors[typedKey]?.message}
				</Alert>
			);
		});
	};

	const {
		register,
		handleSubmit,
		reset,
		getValues,
		formState: { errors, touchedFields },
	} = useForm<sizesSelectorForm>({
		resolver: yupResolver(sizeSelectorSchema),
		defaultValues: production?.sizes,
		shouldFocusError: false,
	});

	const onSubmit = (data: sizesSelectorForm) => {
		handleInputChange(data);
		getTotal(data);
		setFormData(data);

		if (production?.id) {
			if (total > 1)
				dispatch(
					setProductionRequest({
						designId: designId,
						productionId: production?.id,
						orderId: orderId,
						body: data,
					})
				);
		}
	};

	return production?.sizes ? (
		<Wrap>
			<div className="sizes-editor">
				<form onChange={handleSubmit(onSubmit)}>
					<div className="form-row">
						<div className="size-row">
							{Object.keys(production.sizes).map(function (key, i: number) {
								const typedKey = key as keyof sizesSelectorForm;

								return (
									<div className="input-row" key={`${key}_${i}`}>
										<label className="label-sm">{translateSize(key)}</label>
										<input
											className={`${setErrorClass(typedKey)} size-selector-input`}
											type="text"
											{...register(typedKey)}
										/>
									</div>
								);
							})}
						</div>
						<Divider vertical />
						<div className="total-row" key={`total-size`}>
							<label className="label-sm">Total units</label>
							<div className="total">
								<strong>{total}</strong>
							</div>
						</div>
						<div className="total-row">
							<div className="label-sm">Price per unit </div>
							{!orderState.loadingCart && !productionState.loading ? (
								<div className="total">
									{production.lowest_cost &&
									Number(production.lowest_cost) ===
										Number(production.highest_cost) ? (
										<strong>{priceFormat(production.lowest_cost)} €</strong>
									) : (
										<>
											{production.highest_cost && (
												<s>{priceFormat(production.highest_cost)} €</s>
											)}{" "}
											{production.lowest_cost && (
												<strong>{priceFormat(production.lowest_cost)} €</strong>
											)}
										</>
									)}
								</div>
							) : (
								<LoadingText />
							)}
						</div>
					</div>
				</form>
			</div>
			{errors && renderErrors()}
		</Wrap>
	) : (
		<LoadingItem />
	);
};

export default SizeSelector;
