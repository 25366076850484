import React from "react";
import Header from "../../components/Header";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { AppDispatch, RootState } from "../../store";

import Card from "../../components/Card";
import PaddingManager from "../../components/PaddingManager";
import ProductionDesigns from "../../components/ProductionDesigns";
import ProductionSummary from "../../components/ProductionSummary";
import { Wrap } from "./styles";
import { useEffect } from "react";
import {
	getOrderCompletedRequest,
	getOrderSummaryRequest,
} from "../../store/Order";
import { getUserDesignRequest } from "../../store/UserDesign";
import ShippingDetails from "../../components/ShippingDetails";
import { getShippingFromOrderThunk } from "../../store/Shipping";
import { getBillingFromOrderThunk } from "../../store/Billing";
import BillingDetails from "../../components/BillingDetails";
import { dateFormat } from "../../utils/format";
import { useTranslation } from "react-i18next";
import LoadingOptions from "../../components/LoadingOptions";
import Divider from "../../components/Divider";
import { Production, UserDesign } from "../../beans";

const OrderCompleted = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const user = useSelector((state: RootState) => state.user.data);
	const orderConfirmation = useSelector(
		(state: RootState) => state.order.confirmation
	);
	const summary = useSelector((state: RootState) => state.order.summary);
	const orderSummary = summary?.costs;

	const { orderId } = useParams();

	useEffect(() => {
		if (orderId) {
			dispatch(getOrderCompletedRequest(orderId));
			dispatch(getShippingFromOrderThunk(orderId));
			dispatch(getBillingFromOrderThunk(orderId));
			dispatch(getOrderSummaryRequest(orderId));
		}
	}, [orderId, dispatch]);

	useEffect(() => {
		if (orderSummary && orderSummary?.designs?.length > 0) {
			const designId = orderSummary.designs[0]?.design_id;
			if (designId !== undefined) {
				dispatch(getUserDesignRequest(designId));
			}
		}
	}, [orderSummary, dispatch]);

	return (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<Card>
						<div className="order-container">
							<div className="heading-md">
								{t("orderCompleted.orderConfirmed")}
							</div>

							<div className="body-sm">
								{t("orderCompleted.confirmationEmail")}{" "}
								<span className="title-sm">{user?.email}</span>.
							</div>
							{orderConfirmation?.payment[0] && (
								<div className="body-sm">
									{t("orderCompleted.orderDate")}{" "}
									<span className="title-sm">
										{dateFormat(orderConfirmation?.payment[0]?.created_at)}
									</span>
								</div>
							)}
							<div className="body-sm">
								{t("orderCompleted.orderNumber")}{" "}
								<span className="title-sm">{orderId}</span>
							</div>
						</div>
					</Card>

					<div className="order-details-container">
						<div className="order-details-content">
							<Card title="Order details">
								{orderId && (
									<div className="order-details">
										<ShippingDetails orderId={orderId} />
										<BillingDetails orderId={orderId} />
										{orderConfirmation ? (
											<div className="payment">
												<div className="title-sm">
													{" "}
													{t("orderCompleted.payment")}
												</div>
												<div className="body-sm">
													{t("orderCompleted.total")}{" "}
													<strong>
														{orderConfirmation.payment[0].total_amount}{" "}
														{orderConfirmation.payment[0].currency}
													</strong>
												</div>
												<div className="body-sm">
													{t("orderCompleted.status")}{" "}
													<strong>{orderConfirmation.payment[0].status}</strong>
												</div>
												<div className="body-sm">
													{t("orderCompleted.card")}{" "}
													<strong>
														*******{orderConfirmation.payment[0].card_suffix}
													</strong>
												</div>
											</div>
										) : (
											<LoadingOptions />
										)}
									</div>
								)}
							</Card>
							<Card title="Products">
								{orderConfirmation?.orderitems &&
									orderConfirmation?.orderitems?.map(
										(item: { design: UserDesign; production: Production }) => (
											<>
												<ProductionDesigns
													userDesign={item.design}
													key={item.design.id}
													production={item.production}
												/>
												<Divider />
											</>
										)
									)}
							</Card>
						</div>
						<div className="order-summary">
							<ProductionSummary summary={summary} orderId={orderId} />
						</div>
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default OrderCompleted;
