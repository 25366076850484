import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Item } from "../../beans";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../components/Header";
import PaddingManager from "../../components/PaddingManager";
import { AppDispatch, RootState } from "../../store";
import { getItemThunk, selectItem } from "../../store/Item";
import { Wrap } from "./styles";
import ProductCard from "../../components/ProductCard";
import { resetUserDesign, setUserDesignRequest } from "../../store/UserDesign";
import { setCSFRToken } from "../../api/csfr";
import { goToEditDesign, isNewUserRoute } from "../../constants/routes";
import { resetBilling } from "../../store/Billing";
import { resetFabric } from "../../store/Fabric";
import { resetOrders } from "../../store/Order";
import { resetProduction } from "../../store/Production";
import { resetShipping } from "../../store/Shipping";
import { resetTab } from "../../store/UI";
import { resetPrintType } from "../../store/PrintType";
import LoadingCards from "../../components/LoadingCards";
import { useTranslation } from "react-i18next";
import RequestProductCard from "../../components/RequestProductCard";
import { FullStory } from "@fullstory/browser";

const Home = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const itemsState = useSelector((state: RootState) => state.items);
	const { data: items } = itemsState;
	const userDesign = useSelector((state: RootState) => state.userDesign.data);
	const user = useSelector((state: RootState) => state.user.data);

	// HOOKS
	useEffect(() => {
		dispatch(resetProduction());
		dispatch(resetOrders());
		dispatch(resetUserDesign());
		dispatch(resetFabric());
		dispatch(resetTab());
		dispatch(resetPrintType());
		dispatch(resetShipping());
		dispatch(resetBilling());
	}, [dispatch]);

	useEffect(() => {
		setCSFRToken();
		dispatch(getItemThunk());
	}, [dispatch]);

	useEffect(() => {
		if (userDesign?.id) navigate(goToEditDesign(userDesign.id));
	}, [userDesign, navigate]);

	const handleClick = (item: Item) => {
		FullStory("trackEvent", {
			name: "Product clicks",
			properties: {
				product_id: item.id,
			},
		});
		dispatch(selectItem(item));
		dispatch(setUserDesignRequest(item.id));
	};

	return (
		<>
			<Header />
			{isNewUserRoute(window.location.pathname) && (
				<div
					data-tf-live="01JE8FCEPBQJMAEDJGVWJA5HWQ"
					data-tf-hidden={`email=${user?.email},phone=${user?.phone}`}
				></div>
			)}
			<Wrap>
				<PaddingManager>
					<div className="container">
						<div className="title-container">
							<div className="heading-md title">{t("home.title")}</div>
							<div
								className="body-sm description"
								dangerouslySetInnerHTML={{ __html: t("home.description") }}
							/>
						</div>

						{items && !itemsState.loading ? (
							<div className="product-grid">
								{items.map((item: Item) => (
									<ProductCard
										item={item}
										onClick={() => handleClick(item)}
										key={`product_${item.id}`}
									/>
								))}
								<RequestProductCard key={`custom_product`} />
							</div>
						) : (
							<LoadingCards />
						)}
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default Home;
