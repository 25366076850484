import React, { useState } from "react";

import { Wrap } from "./styles";

type Props = {
	item: React.ReactNode;
	full?: boolean;
	children?: React.ReactNode;
};

const DropDown = (props: Props) => {
	const { item, children } = props;
	const [open, setOpen] = useState(false);

	return (
		<Wrap onClick={() => setOpen(!open)} $open={open}>
			<div className="item-action">{item}</div>
			<div className="items">{children}</div>
		</Wrap>
	);
};

export default DropDown;
