import { PrintLocation } from "../beans";

export const getPrintLoactionById = (
	id: string,
	printLocations: PrintLocation[]
) => {
	const location = printLocations.find((location) => location.id === id);
	return location ?? printLocations[0];
};

export const getPrintLoactionByPlacement = (
	placement: string,
	printLocations: PrintLocation[]
) => {
	const location = printLocations.find(
		(location) => location.placement === placement
	);
	return location ?? printLocations[0];
};
