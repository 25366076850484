import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getBillingFromOrderThunk } from "../../store/Billing";
import LoadingOptions from "../LoadingOptions";
import { Wrap } from "./styles";
import { useTranslation } from "react-i18next";

type Props = {
	orderId: string;
};
const BillingDetails = (props: Props) => {
	const { orderId } = props;
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	const billing = useSelector((state: RootState) => state.billing.data);

	useEffect(() => {
		if (orderId) {
			dispatch(getBillingFromOrderThunk(orderId));
		}
	}, [dispatch, orderId]);

	return billing ? (
		<Wrap>
			<div className="list-details">
				<div className="title-sm">{t("orderShipping.billingAddress")}</div>
				<div>
					{billing?.first_name} {billing?.last_name}
				</div>
				{billing?.company_name && <div>{billing?.company_name}</div>}
				{billing?.vat_number && <div>{billing?.vat_number}</div>}
				{billing?.email && <div>{billing?.email}</div>}
				{billing?.phone && <div>{billing?.phone}</div>}
				{billing?.address_line_1 && <div>{billing?.address_line_1}</div>}
				{billing?.address_line_2 && <div>{billing?.address_line_2}</div>}
				{billing?.city && <div>{billing?.city}</div>}
				{billing?.postcode && <div>{billing?.postcode}</div>}
				{billing?.country && <div>{billing?.country}</div>}
			</div>
		</Wrap>
	) : (
		<LoadingOptions />
	);
};

export default BillingDetails;
