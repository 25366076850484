import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	.title-container {
		margin: auto;
		width: 100%;
		text-align: center;
		padding: 24px;

		.description {
			margin-top: 8px;
			color: var(--gray90);
		}
	}
	.product-grid {
		margin: auto;
		display: grid;
		grid-gap: 16px;
		grid-template-columns: repeat(5, 1fr);
		padding-bottom: 48px;
	}

	.custom-product-link {
		margin: 24px auto;
		margin-top: 40px;
		text-align: center;
		a {
			color: var(--purple100);
		}
	}

	@media only screen and (max-width: ${breakpoints.desktopWide}px) {
		.product-grid {
			grid-template-columns: repeat(4, 1fr);
		}
	}

	@media only screen and (max-width: ${breakpoints.desktop}px) {
		.product-grid {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	@media only screen and (max-width: ${breakpoints.tablet}px) {
		.product-grid {
			grid-template-columns: repeat(2, 1fr);
		}
	}

	@media only screen and (max-width: ${breakpoints.tablet}px) {
		.product-grid {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
	}
`;
