import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProductionRange, ProductionRangeAvailability } from "../../beans";
import { apiUrl } from "../../constants/endpoints";
import { call, put, StrictEffect, takeLeading } from "redux-saga/effects";
import { getAuthHeader } from "../../api/auth";

export interface ProductionRangeState {
	data: ProductionRange[];
	error: null | string;
	availableRange: ProductionRangeAvailability[] | [];
	sampleRange: ProductionRange | null;
	selectedRange: ProductionRange | null;
	loading: boolean;
	loaded: boolean;
}

const initialState: ProductionRangeState = {
	data: [],
	error: null,
	availableRange: [],
	sampleRange: null,
	selectedRange: null,
	loading: false,
	loaded: true,
};

function* getProductionRangeSaga(
	action: PayloadAction<string>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<StrictEffect, void, any> {
	try {
		const headers = getAuthHeader();
		const response = yield call(
			fetch,
			`${apiUrl}/designs/userdesigns/${action.payload}/productionrangecost/starting_from_prices/`,
			{
				headers: headers,
				credentials: "include",
			}
		);
		const data = yield response.json();

		if (response.ok) {
			yield put(getProductionRangeSuccess(data));
		} else {
			yield put(getProductionRangeFailure(data));
		}
	} catch (error: unknown) {
		// console.error("ORDER ERRORS", error);
		const errorMessage =
			"The server encountered an unexpected problem. Try again";
		yield put(getProductionRangeFailure(errorMessage));
	}
}

export const getProductionRangeAvailability = createAsyncThunk(
	"production-range-availability/request",
	async () => {
		const response = await fetch(`${apiUrl}/designs/productionrange/`, {
			headers: { accept: "application/json" },
		});

		return await response.json();
	}
);

export const productionRangeSlice = createSlice({
	name: "productionRange",
	initialState,
	reducers: {
		selectProductionRange: (state, action: PayloadAction<ProductionRange>) => {
			state.selectedRange = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		getProductionRangeRequest: (state, _action: PayloadAction<number>) => {
			state.error = initialState.error;
			state.loading = true;
		},
		getProductionRangeSuccess: (
			state,
			action: PayloadAction<ProductionRange[]>
		) => {
			state.data = action.payload;
			state.loading = false;
			state.loaded = true;
		},
		getProductionRangeFailure: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
			state.loaded = true;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getProductionRangeAvailability.pending, (state) => {
			state.availableRange = [];
			state.loading = true;
			state.loaded = false;
		});
		builder.addCase(
			getProductionRangeAvailability.fulfilled,
			(state, action) => {
				state.sampleRange = action.payload[0];
				state.availableRange = action.payload;
				state.loading = false;
				state.loaded = true;
			}
		);
		builder.addCase(
			getProductionRangeAvailability.rejected,
			(state, action) => {
				state.error = action.error.message ?? "Unknown error";
				state.loading = false;
				state.loaded = true;
			}
		);
	},
});

export const {
	selectProductionRange,
	getProductionRangeRequest,
	getProductionRangeSuccess,
	getProductionRangeFailure,
} = productionRangeSlice.actions;
export default productionRangeSlice.reducer;

export function* sagas() {
	yield takeLeading(
		"productionRange/getProductionRangeRequest",
		getProductionRangeSaga
	);
}
