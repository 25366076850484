import React  from 'react';
import Icon from "../Icon";
import { Wrap } from "./styles";
import { loadingLogo } from "../../constants/icons";

const LoadingCanva = () => {
  return (
    <Wrap>
      <Icon icon={loadingLogo} />
    </Wrap>
  );
};

export default LoadingCanva;
