import React  from 'react';
import { Wrap } from "./styles";

type Props = {
  children?: React.ReactNode;
};
const FormContainer = (props: Props) => {
  return <Wrap>{props.children}</Wrap>;
};

export default FormContainer;
