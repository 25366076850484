import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../store";
import {
	getItemRenderListThunk,
	selectRenderItemList,
} from "../../store/ItemRenderList";

import ArtworkCage from "../ArtworkCage";

import { Wrap } from "./styles";
import { ArtworkDetail, ItemRender } from "../../beans";
import {
	getLabelPrintLocationThunk,
	selectLabelPrintLocation,
} from "../../store/PrintLocation";
import { getCanvaFromView } from "../../utils/render";
import LoadingCanva from "../LoadingCanva";
import Label from "../Label";

type Props = {
	canvas: ItemRender[] | null;
};

const RenderLabel = (props: Props) => {
	const { canvas } = props;
	const dispatch = useDispatch<AppDispatch>();
	// selectors

	const userDesign = useSelector((state: RootState) => state.userDesign.data);

	const selectedPrintLocation = useSelector(
		(state: RootState) => state.printLocations.selectedLabel
	);
	const selectedPrintType = useSelector(
		(state: RootState) => state.printType.selectedLabel
	);

	const printLocations = useSelector(
		(state: RootState) => state.printLocations.data
	);

	const selectedItem = useSelector(
		(state: RootState) => state.userDesign?.data?.item_fabric?.item
	);

	const itemRenderListState = useSelector(
		(state: RootState) => state.itemsRenderList
	);

	const itemRenderList = itemRenderListState?.data;

	const selectedFabricColor = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.col_fabric
	);

	const selectedCanva = useSelector(
		(state: RootState) => state.itemsRenderList.selected
	);

	const artworkDetail = useSelector(
		(state: RootState) => state.userDesign.data?.print_set
	);

	const artworkDetailRequest = useSelector(
		(state: RootState) => state.artworkDetails
	);
	const itemId = useSelector(
		(state: RootState) => state.userDesign.data?.item_fabric?.item?.code
	);

	const getCurrentArtwork = () => {
		if (!artworkDetail || !selectedPrintLocation) return;
		const artwork = artworkDetail.find(
			(artwork) =>
				Number(artwork?.location) === Number(selectedPrintLocation?.id)
		);

		setCurrentArtwork(artwork);
	};

	const [currentArtwork, setCurrentArtwork] = useState<
		ArtworkDetail | undefined
	>();

	// HOOKS

	useEffect(() => {
		dispatch(getLabelPrintLocationThunk());
		if (userDesign) {
			dispatch(
				getItemRenderListThunk({
					itemId: userDesign.item_fabric?.item?.id.toString(),
					colorId: userDesign.item_fabric?.id,
					label: true,
				})
			);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		itemRenderList && dispatch(selectRenderItemList(itemRenderList[0]));
	}, [dispatch, itemRenderList]);

	useEffect(() => {
		printLocations && dispatch(selectLabelPrintLocation(printLocations[0]));
	}, [dispatch, printLocations]);

	useEffect(() => {
		getCurrentArtwork();
	}, [selectedItem, selectedFabricColor]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (selectedPrintLocation && canvas) {
			const newCanva = getCanvaFromView(
				canvas,
				selectedPrintLocation.placement,
				selectedCanva?.view.includes("avatar")
			);
			if (newCanva) selectCanva(newCanva);
		}
	}, [selectedPrintLocation]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		// get first if no canvas is selected
		if (canvas && !selectedCanva) selectCanva(canvas[0]);
		// update selectedCanva
		if (canvas && selectedCanva) {
			const newCanva = canvas.find(
				(canva) => canva.view === selectedCanva.view
			);
			newCanva && selectCanva(newCanva);
		}
	}, [canvas]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getCurrentArtwork();
	}, [artworkDetail, selectedPrintLocation]); // eslint-disable-line react-hooks/exhaustive-deps

	const selectCanva = (canva: ItemRender) => {
		if (canva) {
			dispatch(selectRenderItemList(canva));
			dispatch(selectLabelPrintLocation(printLocations[0]));
		}
	};

	return canvas &&
		selectedPrintLocation &&
		userDesign &&
		itemRenderListState.loaded ? (
		<Wrap>
			<div className="product-preview">
				{selectedCanva && (
					<img
						className="product-image"
						src={selectedCanva.image}
						alt=""
						draggable="false"
					/>
				)}

				{selectedPrintType?.id !== "0" && (
					<div
						className={`artwork-area ${selectedPrintLocation?.placement} item_${itemId}`}
					>
						{selectedPrintLocation && (
							<ArtworkCage
								printLocation={selectedPrintLocation}
								show={false}
								htmlId={currentArtwork?.id ?? 0}
							/>
						)}
						{userDesign?.label && (
							<Label
								itemId={itemId}
								label={userDesign?.label}
								printLocation={selectedPrintLocation}
								draggable
							/>
						)}
					</div>
				)}
			</div>

			{artworkDetailRequest.loading && <LoadingCanva />}
		</Wrap>
	) : (
		<LoadingCanva />
	);
};

export default RenderLabel;
