import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	.sizes-editor {
		form {
			.form-row {
				display: flex;
				gap: 16px;
				align-items: start;
				flex-wrap: wrap;
				.vertical {
					align-self: center;
				}
				.size-row {
					display: flex;
					gap: 8px;
				}
				.input-row {
					.size-selector {
						height: 44px;
						width: 56px;
					}
				}
				.total-row {
					display: flex;
					flex-direction: column;
					gap: 4px;
					.total {
						padding: 10px 0;
						strong {
							display: inline-block;
						}
					}
				}
			}
		}
	}

	@media only screen and (max-width: ${breakpoints.desktop}px) {
		.sizes-editor {
			.vertical {
				display: none;
			}
			form {
				.form-row {
					align-items: start;

					.size-row {
						flex-wrap: nowrap;
						.input-row {
							width: 20%;
							input {
								text-align: center;
								.size-selector-input {
									width: 42px;
								}
							}
						}
					}
				}
			}
		}
	}
	@media only screen and (max-width: ${breakpoints.tablet}px) {
		.sizes-editor {
			form {
				.form-row {
					flex-wrap: wrap;
					align-items: center;

					.size-row {
						flex-wrap: nowrap;
						width: 100%;

						.input-row {
							input {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
	@media only screen and (max-width: ${breakpoints.tablet}px) {
		.sizes-editor {
			form {
				.form-row {
					.size-row {
						flex-wrap: wrap;
						width: 100%;
						.input-row {
							input {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
`;
