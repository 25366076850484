import { apiUrl } from "../constants/endpoints";

export const setCSFRToken = () => {
  fetch(`${apiUrl}/csrf_token/`, {
    credentials: "include",
    headers: {
      accept: "application/json",
    },
  })
    .then(function () {
      return true;
    })
    .catch(function (error) {
      console.error('Error fetching CSFR token:', error);
    });
};
