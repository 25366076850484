import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div<{
	$desktopOnly?: boolean;
	$mobileOnly?: boolean;
}>`
	position: sticky;
	margin-top: -30px;
	bottom: 24px;
	display: ${(props) => (props.$mobileOnly ? "none" : "flex")};
	padding: 16px 24px;
	align-items: flex-start;
	gap: 24px;
	border-radius: 4px;
	background: var(--white);
	max-width: 100%;

	.production-detail {
		display: flex;
		width: 100%;
		gap: 24px;
		align-items: center;
		justify-content: center;
		.production-detail-unit {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 4px;
			width: 100%;
		}
		.label-sm {
			color: var(--gray90);
		}
		.title-sm {
			color: var(--gray130);
		}

		&:after {
			content: "";
			display: block;
			width: 1px;
			height: 40px;
			background: var(--purple30);
		}

		&:last-of-type {
			&:after {
				display: none;
			}
		}
	}

	@media only screen and (max-width: ${breakpoints.desktop}px) {
		display: ${(props) => (props.$desktopOnly ? "none" : "flex")};
	}
`;
