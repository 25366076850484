import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

type Props = {
	id: string;
	en?: string;
	es?: string;
};
const TypeFormButton = (props: Props) => {
	const { i18n } = useTranslation();
	const { id, en, es } = props;
	const [typeFormLangId, setTypeFormLangIdg] = useState(
		i18n.language === "en" ? en : es
	);

	const appendTypeFormScript = React.useCallback(() => {
		const script = document.createElement("script");
		script.src = "//embed.typeform.com/next/embed.js";
		script.async = true;
		script.id = "typeform-script";
		script.defer = true;

		setTimeout(() => {
			const typeformButton = document.createElement("div");
			typeformButton.setAttribute("data-tf-live", typeFormLangId || "es");
			typeformButton.id = id;
			const element = document.getElementById(id);
			if (element) {
				element.innerHTML = "";
			}
			document.getElementById(id)?.appendChild(typeformButton);
			document.body.appendChild(script);
		}, 300);
	}, [typeFormLangId, id]);

	useEffect(() => {
		setTypeFormLangIdg(i18n.language === "es" ? en : es);
		const script = document.getElementById("typeform-script");
		const button = document.getElementById("typeform-button");
		script && document.body.removeChild(script);
		button && button.remove();
		appendTypeFormScript();
	}, [i18n.language, appendTypeFormScript, en, es]);

	return <div id={id}></div>;
};

export default TypeFormButton;
