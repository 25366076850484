import styled from "styled-components";

export const Wrap = styled.div`
  path {
    fill: var(--gray130);
  }
  &:hover {
    path {
      fill: var(--gray130);
    }
  }
`;
