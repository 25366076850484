import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  .size-editor {
    display: flex;
    gap: 16px;
  }
  .total-row {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .total {
      padding: 10px 12px;
    }
  }
  @media only screen and (max-width: ${breakpoints.tablet}px) {
    flex-wrap: wrap;
    align-items: center;
    .size-editor {
      width: 100%;
    }
    .vertical {
      display: none;
    }
  }
`;
