import React from "react";
import Icon from "../Icon";
import { Wrap } from "./styles";
import { loadingLogo } from "../../constants/icons";
type Props = {
	transparent?: boolean;
};
const LoadingItem = (props: Props) => {
	return (
		<Wrap $transparent={props.transparent}>
			<Icon icon={loadingLogo} />
		</Wrap>
	);
};

export default LoadingItem;
