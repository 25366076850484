import styled from "styled-components";
import { breakpoints } from "../../constants/breakpoints";

export const Wrap = styled.div`
  background-color: var(--gray20);
  box-sizing: border-box;
  max-width: 100%;
  display: flex;
  gap: 40px;
  height: 100%;
  .thumbnails {
    margin-top: 40px;
    box-sizing: border-box;
    width: 64px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .product-preview {
    position: relative;
    margin: auto;
    margin-top: 0;
    .product-image {
      object-fit: contain;
      box-sizing: border-box;
      max-width: 100%;
      max-height: calc(100vh - 52px);
    }
    .artwork-area {
      position: absolute;
      z-index: 2;

      svg {
        position: absolute;
      }

      &.label_neck {
        width: 19.6%;
        height: 3.5%;
        top: 36%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:before {
          transform: translate(-50%, -50%);
          content: "";
          display: block;
          background: white;
          width: 100%;
          height: 100%;
          position: absolute;
          padding: 5px;
          top: 50%;
          left: 50%;
          box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.3);
        }

        &.item_003 {
          top: 51%;
        }

        &.item_014,
        &.item_015 {
          top: 55%;
        }
        &.item_016,
        &.item_017 {
          top: 53.4%;
        }

        &.item_018 {
          top: 49.5%;
        }
        &.item_019 {
          top: 54%;
        }

        img {
          position: absolute;
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
        }
      }

      .artwork-preview {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 55px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          object-fit: contain;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.desktopWide}px) {
    gap: 0;
  }
  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .product-preview {
      .product-image {
        max-width: 100%;
        max-height: 50vh;
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.desktop}px) {
    .thumbnails {
      margin:auto;
    }
    .product-preview {
      .product-image {
        max-width: 100%;
        max-height: 40vh;
      }
    }
  }
`;
