import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { getDesignRequest } from "../../store/Design";
import { ButtonEnum, UserDesign } from "../../beans";
import { routes } from "../../constants/routes";
import { emptyPage } from "../../constants/icons";
import SubsectionTitle from "../../components/SubsectionTitle";
import PaddingManager from "../../components/PaddingManager";
import DesignPreview from "../../components/DesignPreview";
import Header from "../../components/Header";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { Wrap } from "./styles";
import { resetOrders } from "../../store/Order";
import { resetProduction } from "../../store/Production";
import { resetUserDesign } from "../../store/UserDesign";
import { resetFabric } from "../../store/Fabric";
import { resetTab } from "../../store/UI";
import { resetShipping } from "../../store/Shipping";
import { resetBilling } from "../../store/Billing";
import LoadingCards from "../../components/LoadingCards";
import { useTranslation } from "react-i18next";
import BottomBar from "../../components/BottomBar";

const Design = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();

	// LOCAL STATE
	const designs = useSelector((state: RootState) => state.design.data);
	const selectedDesigns = useSelector(
		(state: RootState) => state.design.selected
	);

	const designRequest = useSelector((state: RootState) => state.design);
	const isLogged = useSelector((state: RootState) => state.login.logged);

	// HOOKS
	useEffect(() => {
		dispatch(resetProduction());
		dispatch(resetOrders());
		dispatch(resetUserDesign());
		dispatch(resetFabric());
		dispatch(resetTab());
		dispatch(resetShipping());
		dispatch(resetBilling());
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		dispatch(getDesignRequest());
	}, [isLogged]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Header />
			<Wrap>
				<PaddingManager>
					<SubsectionTitle title={t("design.title")}>
						<Button
							style={ButtonEnum.primary}
							onClick={() => navigate(routes.home)}
							disabled={selectedDesigns.length > 0}
						>
							{t("design.createNewDesign")}
						</Button>
					</SubsectionTitle>
					{!designRequest.loading ? (
						designs.length > 0 ? (
							<div className="design-grid">
								{designs.map((design: UserDesign, k: number) => {
									return <DesignPreview design={design} key={k} />;
								})}
							</div>
						) : (
							<div className="empty-page">
								<Icon icon={emptyPage} />
								<div className="heading-md">{t("design.noDesignYet")}</div>
								<div className="title-lg">{t("design.designAppearHere")}</div>
							</div>
						)
					) : (
						<LoadingCards />
					)}
				</PaddingManager>
				{selectedDesigns.length > 0 && (
					<BottomBar selectedDesigns={selectedDesigns} />
				)}
			</Wrap>
		</>
	);
};

export default Design;
