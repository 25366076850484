import React  from 'react';
import { Wrap } from "./styles";
type Props = {
  children?: React.ReactNode;
};
const PaddingManager = (props: Props) => {
  return <Wrap className="padding-manager">{props.children}</Wrap>;
};

export default PaddingManager;
