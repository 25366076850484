import styled from "styled-components";

export const Wrap = styled.div`
	&.horizontal {
		width: 100%;
		height: 1px;
		background: var(--purple30);
		margin: 16px 0;
		border: 0;
	}

	&.vertical {
		background: var(--purple30);
		height: 40px;
		width: 1px;
		border: 0;
	}
`;
