import styled from "styled-components";

export const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	.logo {
		margin: auto;
		color: var(--purple100);
	}
	.description {
		text-align: center;
	}
`;
