import React from "react";
import { MouseEventHandler } from "react";
import { Wrap } from "./styles";
import Icon from "../Icon";
import { requestQuote } from "../../constants/icons";
import { useTranslation } from "react-i18next";
import TypeFormButton from "../TypeFormButton";

type Props = {
	size?: string;
	onClick?: MouseEventHandler<HTMLDivElement>;
};
const RequestProductCard = (props: Props) => {
	const { t } = useTranslation();

	return (
		<Wrap onClick={props.onClick} key={"custom"} className={props.size}>
			<div className="request-box">
				<Icon icon={requestQuote} />
				<div
					className="description body-sm"
					dangerouslySetInnerHTML={{ __html: t("requestProduct.description") }}
				/>
				<TypeFormButton
					id="typeform-request-box-request_product"
					en="01JD4KHTVH72PYKW6RC47XDYW0"
					es="01JBYP8KWS21CPQ4ACGAC0M6YW"
				/>
			</div>
		</Wrap>
	);
};

export default RequestProductCard;
