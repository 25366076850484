import React from "react";
import { useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { getStripeSecretKey } from "../../api/stripe";
import { siteUrl } from "../../constants/endpoints";
import Button from "../Button";
import { ButtonEnum } from "../../beans";
import { useTranslation } from "react-i18next";

const CheckoutForm = () => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const orderData = useSelector((state: RootState) => state.order);
	const order = orderData.data;
	const summary = orderData.summary;

	const stripe = useStripe();
	const elements = useElements();
	const [errorMessage, setErrorMessage] = useState<string | undefined>(
		undefined
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoading(true);
		if (order && stripe && elements && summary?.costs) {
			// Trigger form validation and wallet collection
			const { error: submitError } = await elements.submit();
			if (submitError) {
				// Show error to your customer
				setErrorMessage(submitError.message);
				return;
			}

			const res = await getStripeSecretKey(
				order.id,
				Number(summary.costs?.total)
			);

			const { client_secret: clientSecret } = await res;
			const { error } = await stripe.confirmPayment({
				//`Elements` instance that was used to create the Payment Element
				elements,
				clientSecret,
				confirmParams: {
					return_url: `${siteUrl}/order/${order.id}/completed/`,
				},
			});
			if (error) {
				// This point will only be reached if there is an immediate error when
				// confirming the payment. Show error to your customer (for example, payment
				// details incomplete)
				setLoading(false);
				setErrorMessage(error.message);
			} else {
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
			}
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			<Button
				disabled={!stripe || !elements}
				loading={loading}
				style={ButtonEnum.primary}
			>
				{t("button.pay")}
			</Button>
			{/* Show error message to your customers */}
			{errorMessage && <div>{errorMessage}</div>}
		</form>
	);
};
export default CheckoutForm;
