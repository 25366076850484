import { applyMiddleware, createStore, Store } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import createSagaMiddleware, { AnyAction } from "redux-saga";
import reducers from "./reducers";
import sagas from "./sagas"; // Ensure all elements in sagas are generator functions
import { thunk, ThunkDispatch } from "redux-thunk";

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware, thunk];

export const makeStore = () => {
  const store = createStore(
    reducers,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  for (const saga of sagas) {
    if (typeof saga === 'function' && saga.constructor.name === 'GeneratorFunction') {
      sagaMiddleware.run(saga);
    } else {
      console.error('One of the sagas is not a generator function:', saga);
    }
  }
  return store;
};

export type RootState = ReturnType<typeof reducers>;
export type AppStore = Omit<Store<RootState, AnyAction>, "dispatch"> & {
  dispatch: AppThunkDispatch;
};
export type AppDispatch = AppStore["dispatch"];
export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
// export const store = makeStore(); // it cause double calls for each action,  TODO fix it for JEST tests 