import React from "react";
import { useSelector } from "react-redux";
import PaddingManager from "../../components/PaddingManager";
import RegistrationForm from "../../components/RegistrationForm";
import { RootState } from "../../store";
import { Wrap } from "./styles";
import LoginForm from "../../components/LoginForm";
import Header from "../../components/Header";
import LangSwitcher from "../../components/LangSwitcher";
import { useTranslation } from "react-i18next";

const Access = () => {
	const access = useSelector((state: RootState) => state.ui.access);
	const { t } = useTranslation();

	return (
		<>
			<Wrap>
				<Header hidden />

				<div className="heading">
					<PaddingManager>
						<div className="heading-container">
							<div className="heading-image">
								<img
									src="https://fabbric-public-images.ams3.digitaloceanspaces.com/UI/registration-image.png"
									alt="landing"
								/>
							</div>
							<div className="heading-text">
								<div className="title-lg title">{t("access.title")}</div>
								<div className="heading-list">
									<div className="list-item">
										<div className="list-title title-sm">
											{t("access.trending.title")}
										</div>
										<div className="list-text">
											{t("access.trending.description")}
										</div>
									</div>
									<div className="list-item">
										<div className="list-title title-sm">
											{t("access.minimum.title")}
										</div>
										<div className="list-text">
											{t("access.minimum.description")}
										</div>
									</div>
									<div className="list-item">
										<div className="list-title title-sm">
											{" "}
											{t("access.europe.title")}
										</div>
										<div className="list-text">
											{t("access.europe.description")}
										</div>
									</div>
								</div>
							</div>
						</div>
					</PaddingManager>
				</div>
				<div className="form">
					<PaddingManager>
						<div className="form-container">
							{access.login ? <LoginForm /> : <RegistrationForm />}
							<div className="lang-switcher">
								<LangSwitcher />
							</div>
						</div>
					</PaddingManager>
				</div>
			</Wrap>
		</>
	);
};

export default Access;
