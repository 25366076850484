import React  from 'react';
import { Wrap } from "./styles";

const LoadingCards = () => {
  return (
    <Wrap>
      <div className="product-grid">
        <div className="block">
          <div className="card animate"></div>
          <div className="title animate"></div>
        </div>
        <div className="block">
          <div className="card animate"></div>
          <div className="title animate"></div>
        </div>
        <div className="block">
          <div className="card animate"></div>
          <div className="title animate"></div>
        </div>
        <div className="block">
          <div className="card animate"></div>
          <div className="title animate"></div>
        </div>
        <div className="block">
          <div className="card animate"></div>
          <div className="title animate"></div>
        </div>
        <div className="block">
          <div className="card animate"></div>
          <div className="title animate"></div>
        </div>
        <div className="block">
          <div className="card animate"></div>
          <div className="title animate"></div>
        </div>
        <div className="block">
          <div className="card animate"></div>
          <div className="title animate"></div>
        </div>
      </div>
    </Wrap>
  );
};

export default LoadingCards;
