import React from "react";
import { Wrap } from "./styles";
type Props = {
	vertical?: boolean;
};
const Divider = (props: Props) => {
	return (
		<Wrap
			className={props.vertical ? "divider vertical" : "divider horizontal"}
		></Wrap>
	);
};

export default Divider;
