import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";
import { routes } from "../../constants/routes";
import ProductionSummary from "../../components/ProductionSummary";
import PaddingManager from "../../components/PaddingManager";
import ShippingForm from "../../components/ShippingForm";
import Header from "../../components/Header";

import Card from "../../components/Card";
import { Wrap } from "./styles";
import { useTranslation } from "react-i18next";
import { getOrderByIdRequest, getOrderSummaryRequest } from "../../store/Order";

const OrderShipping = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const { t } = useTranslation();

	const { orderId } = useParams();

	const summary = useSelector((state: RootState) => state.order.summary);

	useEffect(() => {
		if (!orderId) {
			navigate(routes.home);
		}
		dispatch(getOrderByIdRequest(orderId));
		dispatch(getOrderSummaryRequest(orderId));
	}, [dispatch, orderId, navigate]);

	return (
		<>
			<Header product onClose={() => navigate(routes.home)} />
			<Wrap>
				<PaddingManager>
					<div className="page-containers">
						<div className="page-content">
							<Card title={t("orderShipping.shipping")} active>
								{orderId && <ShippingForm orderId={orderId} />}
							</Card>
						</div>

						<ProductionSummary summary={summary} orderId={orderId} />
					</div>
				</PaddingManager>
			</Wrap>
		</>
	);
};

export default OrderShipping;
