import React  from 'react';
import { MouseEventHandler, useState } from "react";
import LoadingProduct from "../LoadingProduct";
import { Wrap } from "./styles";

type Props = {
  src: string;
  alt?: string;
  draggable?: boolean;
  size?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
};
const ProductImage = (props: Props) => {
  const { src, alt, draggable, size } = props;
  const [loadedImage, setLoadedImage] = useState(false);

  return (
    <Wrap className="product-image">
      {!loadedImage && <LoadingProduct size={size} />}
      <img
        src={src}
        alt={alt}
        className={loadedImage ? "loaded" : ""}
        draggable={draggable ? "true" : "false"}
        onLoad={() => {
          setLoadedImage(true);
        }}
      />
    </Wrap>
  );
};

export default ProductImage;
