import React  from 'react';
import { useEffect, useRef, useState } from "react";
import Moveable from "react-moveable";
import { flushSync } from "react-dom";
import { ArtworkDetail, PrintLocation } from "../../beans";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import { patchUserDesignLabelThunk } from "../../store/UserDesign";

import {
  calclulatePreviewImageTranslation,
  getDOMMatrixScale,
  convertToCm,
  convertToPx,
} from "../../utils/sizes";

import { Wrap } from "./styles";

import { loadingLogo } from "../../constants/icons";
import Icon from "../Icon";

type Props = {
  label: ArtworkDetail;
  children?: React.ReactNode;
  printLocation: PrintLocation;
  draggable?: boolean;
  itemId?: string;
};
const Label = (props: Props) => {
  const dispatch = useDispatch<AppDispatch>();

  // PROPS
  const { label, printLocation, draggable } = props;

  // REF
  const targetRef = useRef<HTMLImageElement>(null);
  const moveableRef = useRef<Moveable>(null);

  // ROOT STATE
  const userDesign = useSelector((state: RootState) => state.userDesign.data);
  const cages = useSelector((state: RootState) => state.ui.cages);

  // LOCAL STATE
  const [cageWidth, setCageWidth] = useState<number | undefined>(undefined);
  const [cageHeight, setCageHeight] = useState<number | undefined>(undefined);

  // update moveableRef on resize
  useEffect(() => {
    const handleResize = () => {
      moveableRef.current?.updateSelectors();
      moveableRef.current?.updateRect();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // update moveableRef on change artwork
  useEffect(() => {
    if (moveableRef.current) {
      moveableRef.current?.updateSelectors();
      moveableRef.current?.updateRect();
    }
  }, [label]);

  // save artwork on load
  useEffect(() => {
    if (moveableRef.current) {
      moveableRef.current?.updateSelectors();
      moveableRef.current?.updateRect();

      setTimeout(() => {
        saveOnLoadArtwork(targetRef.current);
      }, 300);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveableRef.current, cageWidth]);

  // set cage sizes
  useEffect(() => {
    if (cages.length > 0) {
      const cage = cages[0];

      if (cage) {
        setCageWidth(Number(cage?.width));
        setCageHeight(Number(cage?.height));
      }
    }
  }, [cages]);

  //// SAVE ON LOAD /////
  const saveOnLoadArtwork = (target: HTMLImageElement | null) => {
    if (!cageWidth || !cageHeight || !target) return;

    if (label.position_x === null && label.width_cm === 0) {
      // retreive scale from transform
      const matrix = new DOMMatrix(target.style.transform);
      const s = getDOMMatrixScale(matrix);

      // retreive real cm dimensions
      const width_cm = convertToCm(
        cageWidth,
        printLocation.real_width_cm,
        target.getBoundingClientRect().width
      );

      const height_cm = convertToCm(
        cageHeight,
        printLocation.real_height_cm,
        target.getBoundingClientRect().height
      );

      const position_x = (Number(printLocation.real_width_cm) - width_cm) / 2;
      const position_y = (Number(printLocation.real_height_cm) - height_cm) / 2;

      if (width_cm > 0 && height_cm > 0) {
        dispatch(
          patchUserDesignLabelThunk({
            designId: userDesign?.id?.toString() || '',
            labelId: label.id.toString(),
            payload: {
              position_x: position_x,
              position_y: position_y,
              scale: Number(s),
              width_cm: width_cm,
              height_cm: height_cm,
            },
          })
        );
      }
    }
  };

  //// SAVE ON DRAG /////
  const saveDragArtWork = (event: { target: HTMLElement | SVGElement }) => {
    if (!userDesign) return;

    // update the posiion attributes
    const target = event.target;

    // update the posiion attributes
    const tx = new DOMMatrix(target.style.transform).e; //TODO create a decent bean for x y matrix coordinates
    const ty = new DOMMatrix(target.style.transform).f;

    const position_x_cm = convertToCm(
      Number(cageWidth),
      printLocation.real_width_cm,
      tx
    );

    const position_y_cm = convertToCm(
      Number(cageHeight),
      printLocation.real_height_cm,
      ty
    );

    dispatch(
      patchUserDesignLabelThunk({
        designId: userDesign?.id?.toString(),
        labelId: label.id.toString(),
        payload: {
          position_x: position_x_cm,
          position_y: position_y_cm,
        },
      })
    );
  };

  //// SAVE ON SCALE /////
  const saveScaleArtWork = (event: { target: HTMLElement | SVGElement }) => {
    if (!userDesign) return;

    const target = event.target;
    const moveableCoordinates = moveableRef.current?.getRect();

    if (moveableCoordinates && cageWidth) {
      const position_x_cm = convertToCm(
        Number(cageWidth),
        printLocation.real_width_cm,
        moveableCoordinates.left
      );

      const position_y_cm = convertToCm(
        Number(cageWidth),
        printLocation.real_width_cm,
        moveableCoordinates.top
      );
      const width_cm = convertToCm(
        cageWidth,
        printLocation.real_width_cm,
        target.getBoundingClientRect().width
      );

      const height_cm = convertToCm(
        cageWidth,
        printLocation.real_width_cm,
        target.getBoundingClientRect().height
      );

      dispatch(
        patchUserDesignLabelThunk({
          designId: userDesign?.id?.toString(),
          labelId: label.id.toString(),
          payload: {
            width_cm: width_cm,
            height_cm: height_cm,
            position_x: position_x_cm,
            position_y: position_y_cm,
          },
        })
      );
    }
  };

  return (
    <Wrap $draggable={draggable}>
      {draggable && (
        <Moveable
          key={`moveable_comp_${label.id}`}
          flushSync={flushSync}
          ref={moveableRef}
          origin={false}
          target={targetRef}
          hideDefaultLines={true}
          useAccuratePosition={true}
          snappable={true}
          snapDirections={{ middle: true, center: true }}
          draggable={true}
          edgeDraggable={false}
          throttleDrag={0}
          // scalable={true}
          throttleScale={0}
          keepRatio={true}
          bounds={{ left: 0, top: 0, right: 0, bottom: 0, position: "css" }}
          verticalGuidelines={[cageWidth ? cageWidth / 2 : 0]}
          horizontalGuidelines={[cageHeight ? cageHeight / 2 : 0]}
          onRender={(e) => {
            e.target.style.transform = e.transformObject.transform;
          }}
          onDrag={({ target, transform }) => {
            target.style.transform = transform;
          }}
          onDragEnd={(e) => {
            saveDragArtWork(e);
          }}
          onScale={({ target, transform }) => {
            target.style.transform = transform;
          }}
          onScaleStart={({ setFixedDirection }) => {
            setFixedDirection([0, 0]);
          }}
          onScaleEnd={(e) => {
            saveScaleArtWork(e);
          }}
        />
      )}
      {cageWidth ? (
        <img
          className="artwork-img"
          ref={targetRef}
          id={draggable ? `moveable_${label.id}` : `${label.id}`}
          src={label.original_resized} // TODO select from print_placement
          alt=""
          style={{
            transform: calclulatePreviewImageTranslation(
              label,
              printLocation,
              cageWidth
            ),
            width:
              label.width_cm && cageWidth
                ? convertToPx(
                    cageWidth,
                    printLocation.real_width_cm,
                    label.width_cm
                  )
                : "",
            height:
              label.height_cm && cageHeight
                ? convertToPx(
                    cageWidth,
                    printLocation.real_width_cm,
                    label.height_cm
                  )
                : "",
          }}
        />
      ) : (
        <div className="loading-artwork">
          <Icon icon={loadingLogo} />
        </div>
      )}
    </Wrap>
  );
};

export default Label;
