import React from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store";

import {
	ActionCardEnum,
	AlertEnum,
	ButtonEnum,
	OrderItem as OrderItemBean,
	SampleProduction,
} from "../../beans";
import Card from "../../components/Card";
import ActionCard from "../../components/ActionCard";
import ProductionDesigns from "../../components/ProductionDesigns";
import SubsectionTitle from "../../components/SubsectionTitle";

import { goToEditDesign } from "../../constants/routes";
import LoadingOptions from "../../components/LoadingOptions";
import Alert from "../../components/Alert";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import { showInfoBox } from "../../store/UI";
import { cloneToOrderRequest, removeFromOrderRequest } from "../../store/Order";
import Icon from "../Icon";
import { duplicate, pencil, trash } from "../../constants/icons";
import LoadingProduct from "../LoadingProduct";
import {
	initialProduction_100,
	initialProduction_50,
	initialProduction_200,
	initialProduction_300,
} from "../../constants/productions";
import { setProductionRequest } from "../../store/Production";
import SizeSelector from "../SizeSelector";
import SampleSizeSelector from "../SampleSizeSelector";

type Props = {
	orderId: number;
	orderItem: OrderItemBean;
	designId: number;
};
const OrderItem = (props: Props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { t } = useTranslation();
	const orderId = props.orderId;
	const orderItem = props.orderItem;
	const userDesign = orderItem.design;
	const designId = userDesign.id;
	const production = orderItem.production;

	// GLOBAL STATE
	const selectedProductionRange = useSelector(
		(state: RootState) => state.productionRange.selectedRange
	);

	const isSample = production !== null ? production.sample : true;

	const minimum = useSelector(
		(state: RootState) => state.productionRange.selectedRange?.minimum
	);
	// LOCAL STATE
	const [sample, setSample] = useState(isSample);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isCloning, setIsCloning] = useState(false);

	const setDefaultProdutcQuantity = () => {
		const payload = {
			productionId: production?.id || 0,
			orderId: orderId,
			body: initialProduction_50,
			designId: designId,
		};

		switch (minimum) {
			case 50:
				dispatch(setProductionRequest(payload));
				return;
			case 100:
				payload.body = initialProduction_100;
				dispatch(setProductionRequest(payload));
				return;
			case 151:
			case 200:
				payload.body = initialProduction_200;
				dispatch(setProductionRequest(payload));
				return;
			case 301:
				payload.body = initialProduction_300;
				dispatch(setProductionRequest(payload));
				return;
			default:
				payload.body = initialProduction_50;
				dispatch(setProductionRequest(payload));
				return;
		}
	};

	// HANDLERS
	const handleOrderSample = (sample: boolean) => {
		setSample(sample);

		if (!sample) {
			handleOrderQuantity();
		} else {
			const initialSampleProductionPayload = {
				sample: true,
				small: 0,
				medium: 0,
				large: 0,
				x_large: 0,
			};
			(initialSampleProductionPayload as SampleProduction)["small"] = 1;

			dispatch(
				setProductionRequest({
					productionId: production?.id || 0,
					orderId: props.orderId,
					designId: props.designId,
					body: initialSampleProductionPayload,
				})
			);
		}
	};

	const handleOrderQuantity = () => {
		setDefaultProdutcQuantity();
	};

	const handleDeleteItem = (itemId: number) => {
		setIsDeleting(true);
		dispatch(
			removeFromOrderRequest({
				orderId: orderId,
				item: itemId,
			})
		);
	};

	const renderCardActions = () => {
		return userDesign?.id ? (
			<>
				<Button
					style={ButtonEnum.link}
					title={t("button.edit")}
					onClick={() => userDesign && navigate(goToEditDesign(userDesign.id))}
				>
					<Icon icon={pencil} />
				</Button>
				<Button
					style={ButtonEnum.link}
					title={t("button.duplicate")}
					onClick={() => {
						setIsCloning(true);
						dispatch(
							cloneToOrderRequest({ orderId: orderId, design: userDesign.id })
						);
					}}
				>
					<Icon icon={duplicate} />
				</Button>
				<Button
					style={ButtonEnum.link}
					title={t("button.remove")}
					onClick={() => handleDeleteItem(props.orderItem.id)}
				>
					<Icon icon={trash} />
				</Button>
			</>
		) : null;
	};

	return isDeleting ? null : isCloning ? (
		<LoadingProduct />
	) : (
		<Card title={userDesign?.name} active action={renderCardActions()}>
			{userDesign && production && (
				<ProductionDesigns
					edit
					production={production}
					userDesign={userDesign}
				/>
			)}

			{production ? (
				<div className="order-type">
					<SubsectionTitle title={t("orderProduction.orderType")} />
					<div className="order-type-options">
						<ActionCard
							onClick={() => handleOrderSample(true)}
							key={"sample-production"}
							type={ActionCardEnum.expanded}
							title={t("orderProduction.orderSample")}
							label={t("orderProduction.orderSampleDescription")}
							selected={sample}
						/>
						<ActionCard
							onClick={() => handleOrderSample(false)}
							key={"order-production"}
							type={ActionCardEnum.expanded}
							title={t("orderProduction.orderProduction")}
							label={t("orderProduction.orderProductionDescription")}
							selected={!sample}
						/>
					</div>
					{production && (
						<div className="order-type-info">
							<Alert type={AlertEnum.success}>
								{sample ? (
									<span
										dangerouslySetInnerHTML={{
											__html: t("orderProduction.alertSample"),
										}}
									></span>
								) : (
									<span
										dangerouslySetInnerHTML={{
											__html: t("orderProduction.alertProduction"),
										}}
									></span>
								)}
							</Alert>
						</div>
					)}
				</div>
			) : (
				<LoadingOptions />
			)}
			{/* IF ORDER-TYPE */}
			{production ? (
				<div className="order-quantity">
					{!sample ? (
						<>
							<SubsectionTitle title={t("orderProduction.sizeSelection")}>
								<Button
									style={ButtonEnum.link}
									onClick={() =>
										dispatch(
											showInfoBox({
												open: true,
												title: t("orderProduction.sizeGuide"),
												key: "size_guide",
											})
										)
									}
								>
									{t("orderProduction.sizeGuide")}
								</Button>
							</SubsectionTitle>
							{production && orderId && (
								<div className="size-selector-container">
									<SizeSelector
										key={"size-selector"}
										orderId={orderId}
										designId={designId}
										production={production}
										selectedProductionRange={selectedProductionRange}
									/>
								</div>
							)}
						</>
					) : (
						<>
							<SubsectionTitle title={t("orderProduction.sizeSelection")}>
								<Button
									style={ButtonEnum.link}
									onClick={() =>
										dispatch(
											showInfoBox({
												open: true,
												title: t("orderProduction.sizeGuide"),
												key: "size_guide",
											})
										)
									}
								>
									{t("orderProduction.sizeGuide")}
								</Button>
							</SubsectionTitle>
							{orderId && (
								<SampleSizeSelector
									key={"sample-selector"}
									orderId={orderId}
									production={production}
									designId={designId}
								/>
							)}
						</>
					)}
				</div>
			) : (
				<LoadingOptions />
			)}
		</Card>
	);
};

export default OrderItem;
